/**
 * @author mtiwari
 * Updated on 21 May 2024
 * Added two list i.e. segmentNotApplicableList, guideStatusNotApplicableList which holds the chart for which segment and guide status filter is not required.
 * This is a second level check
 * Updated on 22 May 2024
 * added undefined check for api prop
 */

import React, { useEffect, useCallback, useState, useRef } from "react";
import { toast } from "react-toastify";
import withRouter from "hoc/withRouter";
import { useNavigate, useLocation } from 'react-router-dom';

import { OverlayTrigger } from "react-bootstrap";
import { Tooltip } from "react-bootstrap";
import { Tab, Tabs } from "react-bootstrap";


import { useSelector } from "react-redux";
import { AppsState, DomainState, DatesState, EventSourceState, StatusFilterState, SegmentsFilterState, LanguageState } from "store/Reducers";
import { SearchState } from "store/Reducers/GuideInsights/searches";
import { deleteCall } from "utils/ApiCallActions";
import { TooltipState } from "store/Reducers/GuideInsights/tooltips";


import qs from 'querystring';
import { CommonCharts } from "utils/DashboardCharts";
import { ChartColors, RetryApi } from "utils/GlobalConstants";


import SectionRefresh from "../SectionRefresh";
import CardContainer from "../CardContainer";
import ContainerTooltip from "../ContainerTooltip";
import SectionTooltip from "components/SectionTooltip/SectionTooltip";
import StateFilterDashboard from "components/StateFilter/StateFilterDashboard";
import StateFilter from "components/StateFilter/StateFilter";
import Loader from "components/Loader/Loader";
import RadialChart from "components/Chart/apexCharts/ApexRadialChart2";
import EventSourceDashboard from 'components/EventSource/EventSourceDashboard';
import EventSource from 'components/EventSource/EventSource';
import WordCloud from "components/Chart/WordCloud";
import Linechart from "components/Chart/Linechart";
import Piechart from "components/Chart/nvd3Charts/Piechart";
import Barchart from "components/Chart/Barchart";
import CommonModal from "components/CommonModal/CommonModal";
import Segments from 'components/SegmentationFilter/Segmentation';


import "../SectionHeader.scss";
import "./Type1.scss";
import TooltipModal from "components/CommonModal/Tooltip/TooltipModal";




import { InsightLabels } from "labels";

interface CurrentState {
    apps: AppsState,
    domain: DomainState,
    dates: DatesState,
    eventSource: EventSourceState,
    searches: SearchState,
    statusFilter: StatusFilterState,
    tooltips: TooltipState,
    segmentsFilter: SegmentsFilterState,
    languageData: LanguageState
}

const Type1 = (props: any) => {

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    const isInitialMount = useRef(true);
    const navigate = useNavigate();
    let fetched_details = useSelector((state: CurrentState) => state);
    let tooltip: any;

    const dateZone: any = new Date().toString();
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];

    let segmentNotApplicableList = ["BEACONS_CHART",
        "PUSH_NOTIFICATIONS_CHART",
        "USER_ENGAGEMENT_GUIDE_ME_CHART",
        "USER_ENGAGEMENT_SHOW_ME_CHART",
        "USER_ENGAGEMENT_TEST_ME_CHART",
        "USER_ENGAGEMENT_DO_IT_FOR_ME_CHART",
        "USER_ENGAGEMENT_TUTORIAL_GUIDES_CHART",
        "SEARCH_CHART",
        "SEARCH_STATS",
        "TASK_LIST_CHART"];

    let guideStatusNotApplicableList = ["BEACONS_CHART",
        "PUSH_NOTIFICATIONS_CHART",
        "USER_ENGAGEMENT_GUIDE_ME_CHART",
        "USER_ENGAGEMENT_SHOW_ME_CHART",
        "USER_ENGAGEMENT_TEST_ME_CHART",
        "USER_ENGAGEMENT_DO_IT_FOR_ME_CHART",
        "USER_ENGAGEMENT_TUTORIAL_GUIDES_CHART",
        "SEARCH_CHART",
        "SEARCH_STATS",
        "TASK_LIST_CHART"
    ];

    const [filterValues, setFilterValues] = useState({
        eventSourceValue: props?.data?.eventSourceFilter,
        guideStatusValue: props?.data?.guideStatusFilter,
        segmentsGroup: {
            segmentsFilter: props?.data?.segmentsGroup?.segmentsFilter?.segmentsFilter ?? 0,
            segmentTitle: props?.data?.segmentsGroup?.segmentsFilter?.segmentTitle ?? ''
        }
    })

    const [guideIds, setGuideIds] = useState({
        list: props.data.tooltip_guideIds
    })

    const [chartState, setChartState] = useState({
        data: [],
        data_fetched: false,
        serviceAvailable: true,
        type: '',
        options: [],
        lineChartMemoFetched: false,
        lineChartMemo: {},
        radialChartCount: 0,
        totalGuideMeCount: 0,
        totalGuideMeCompleteCount: 0,
        dummyDonutData: [],
        dummyPieData: [],
        search_data: [],
        search_data_fetched: false,
        word_data: [],
    })

    const [deleteModal, setDeleteModal] = useState({
        open: false,
        chartID: ''
    })

    const [showMeTabKey, setShowMeTabKey] = useState({
        key: 0,
        chart: []
    });

    let activity_linechart_height = 400;

    let lineChartMargin = {
        top: "50",
        right: "50",
        bottom: "50",
        left: "65",
    }

    const pie_margin = {
        top: 50,
        bottom: 5,
        left: 0,
        right: 0
    }

    // useEffect(() => {
    //     if(props.data){
    //         switch (props.data.chartType) {
    //             // Single Div
    //             case 'WordCloud':
    //                 setChartState((prevState: any) => {
    //                     return {
    //                         ...prevState,
    //                         type: props.data.chartType,
    //                         data_fetched: false,
    //                         search_data_fetched: false
    //                     }
    //                 })
    //                 getWordCloudData(props.data.api);
    //                 getSearchStats(props.data.api);
    //                 break;

    //             case 'LinePieChart':
    //                 setChartState((prevState: any) => {
    //                     return {
    //                         ...prevState,
    //                         type: props.data.chartType,
    //                         data_fetched: false,
    //                     }
    //                 })
    //                 getLineChart_data(props.data.api, props.data.sectionHeader,1);
    //                 break;

    //             case 'LineChart':
    //                 setChartState((prevState: any) => {
    //                     return {
    //                         ...prevState,
    //                         type: props.data.chartType,
    //                         data_fetched: false,
    //                     }
    //                 })
    //                 getLineChart_data(props.data.api, props.data.sectionHeader,1);
    //                 break;

    //             case 'BarChart':
    //                 setChartState((prevState: any) => {
    //                     return {
    //                         ...prevState,
    //                         type: props.data.chartType,
    //                         data_fetched: false,
    //                     }
    //                 })
    //                 getBarChart_data(props.data.api, props.data.sectionHeader);
    //                 break;


    //             case 'LinePieDonutChart':
    //                 setChartState((prevState: any) => {
    //                     return {
    //                         ...prevState,
    //                         type: props.data.chartType,
    //                         data_fetched: false,
    //                     }
    //                 })
    //                 getLineChart_data(props.data.api, props.data.sectionHeader,1);
    //                 break;
    //         }
    //     }

    // }, [props.data])

    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    const getWordCloudData = (api: any, apiErrorFlag?: string) => {
        let params = {
            "start_date": fetched_details?.dates?.fdate + "_00:00:00",
            "end_date": fetched_details?.dates?.tdate + "_23:59:59",
            "time_zone": timeZone,
            "app_id": props.data.appID,
            "event_source": filterValues.eventSourceValue,
            'exclude_weekends': fetched_details?.dates?.excludeFlag,
            'enable_real_time': fetched_details.dates.realTimeCheck
        };

        if (filterValues.eventSourceValue !== "" && fetched_details.dates.fdate !== "" && fetched_details.dates.tdate !== "") {
            CommonCharts.wordCloudChart(api, params, apiErrorFlag ?? '').then((data: any) => {

                if (data) {
                    if (data?.retry) {
                        setTimeout(() => {
                            getSearchStats(api, 'retry')
                        }, RetryApi.TIMEOUT);
                    } else {
                        setChartState((prevState: any) => {
                            return {
                                ...prevState,
                                word_data: data.data,
                                data_fetched: data.data_fetched,
                                serviceAvailable: data.serviceAvailable,
                            }
                        })
                        props.noError(data.serviceAvailable)
                    }

                }
            });
        }
    }

    const getSearchStats = (api: any, apiErrorFlag?: string) => {
        let params = {
            "start_date": fetched_details?.dates?.fdate + "_00:00:00",
            "end_date": fetched_details?.dates?.tdate + "_23:59:59",
            "time_zone": timeZone,
            "app_id": props.data.appID,
            "event_source": filterValues.eventSourceValue,
            'exclude_weekends': fetched_details?.dates?.excludeFlag,
            'enable_real_time': fetched_details.dates.realTimeCheck
        };
        if (filterValues.eventSourceValue !== "" && fetched_details.dates.fdate !== "" && fetched_details.dates.tdate !== "") {
            CommonCharts.searchStats(api, params, apiErrorFlag ?? '').then((data: any) => {
                if (data) {
                    if (data?.retry) {
                        setTimeout(() => {
                            getSearchStats(api, 'retry')
                        }, RetryApi.TIMEOUT);
                    } else {
                        for (let i = 0; i < data?.data?.length; i++) {
                            if (i === 0)
                                data.data[i].tooltip = insightsLbls['searchCount']
                            else if (i === 1)
                                data.data[i].tooltip = insightsLbls['actionCount']

                        }
                        setChartState((prevState: any) => {
                            return {
                                ...prevState,
                                search_data: data.data,
                                data_fetched: data.data_fetched,
                                serviceAvailable: data.serviceAvailable,
                            }
                        })

                        props.noError(data.serviceAvailable)

                    }

                }
            });
        }
    }

    const getBarChart_data = (api: any, sectionHeader: string, apiErrorFlag?: string) => {
        let params = {
            "app_id": props.data.appID,
            "time_zone": timeZone,
            "start_date": fetched_details?.dates?.fdate + "_00:00:00",
            "end_date": fetched_details?.dates?.tdate + "_23:59:59",
            "event_source": filterValues.eventSourceValue,
            "filter": filterValues.guideStatusValue.toLowerCase(),
            'exclude_weekends': fetched_details?.dates?.excludeFlag,
            'enable_real_time': fetched_details.dates.realTimeCheck,
            'type': "org",
        };



        const data = {
            'segment_group': filterValues.segmentsGroup.segmentsFilter
        }

        if (props.data.component === 'tooltip' || props.data.component === 'guides') {
            params = { ...params, ...data }
        }

        if (filterValues.eventSourceValue !== "" && fetched_details.dates.fdate !== "" && fetched_details.dates.tdate !== "") {
            CommonCharts.barChart(api, params, sectionHeader, apiErrorFlag ?? '').then((data: any) => {
                if (data) {
                    if (data?.retry) {
                        setTimeout(() => {
                            getBarChart_data(api, sectionHeader, 'retry')
                        }, RetryApi.TIMEOUT);
                    } else {
                        setChartState((prevState: any) => {
                            return {
                                ...prevState,
                                data: data.data,
                                data_fetched: data.data_fetched,
                                serviceAvailable: data.serviceAvailable,
                                options: data.options,
                            }
                        })
                    }
                    props.noError(data.serviceAvailable)
                }
            });
        }
    }

    const getLineChart_data = (api: any, sectionHeader: string, guideIds?: any, apiErrorFlag?: string, optn?: number) => {

            let params = {}
            if (api && (api[0] === 'TOOLTIP_VALIDATION' || api[0] === 'DUCT_TAPE' || api[0] === 'POWER_FORM_STACKED_CHART' || api[0].includes('USER_ENGAGEMENT'))) {
                params = {
                    "end_date": fetched_details?.dates?.tdate + "_23:59:59",
                    "app_code": props.data.appCode,
                    "start_date": fetched_details?.dates?.fdate + "_00:00:00",
                    "time_zone": timeZone,
                    'event_source': filterValues?.eventSourceValue,
                    'exclude_weekends': fetched_details?.dates?.excludeFlag,
                    'enable_real_time': fetched_details.dates.realTimeCheck,
                };
            } else {
                if (props.data.component === 'taskList') {
                    params = {
                        "app_code": props.data.appCode,
                        "time_zone": timeZone,
                        "start_date": fetched_details?.dates?.fdate + "_00:00:00",
                        "end_date": fetched_details?.dates?.tdate + "_23:59:59",
                        "event_source": filterValues?.eventSourceValue,
                    };
                } else {
                    params = {
                        "app_id": props.data.appID,
                        "time_zone": timeZone,
                        "start_date": fetched_details?.dates?.fdate + "_00:00:00",
                        "end_date": fetched_details?.dates?.tdate + "_23:59:59",
                        "event_source": filterValues?.eventSourceValue,
                        "filter":  (filterValues?.guideStatusValue).toString().toLowerCase(),
                        'exclude_weekends': fetched_details?.dates?.excludeFlag,
                        'enable_real_time': fetched_details.dates.realTimeCheck,
                        'type': "org",
                    };
                }
    
            }
    
    
            const data = {
                'segment_group': filterValues.segmentsGroup.segmentsFilter
            }
    
    
            if (props.data.component === 'tooltip' || props.data.component === 'guides') {
                params = { ...params, ...data }
            }
    
            if (filterValues?.eventSourceValue !== "" && fetched_details?.dates?.tdate !== "" && fetched_details?.dates?.fdate !== "") {
                CommonCharts.lineChart(api, params, sectionHeader, guideIds, apiErrorFlag ?? '', optn).then((data: any) => {
                    if (data) {
                        if (data?.retry) {
                            setTimeout(() => {
                                getLineChart_data(api, sectionHeader, guideIds, 'retry')
                            }, RetryApi.TIMEOUT);
                        } else {
                            setChartState((prevState: any) => {
                                return {
                                    ...prevState,
                                    data: data.data,
                                    data_fetched: data.data_fetched,
                                    serviceAvailable: data.serviceAvailable,
                                    options: data.options,
                                    radialChartCount: data.ratioVal,
                                    totalGuideMeCount: data.totalGuideMeCount,
                                    totalGuideMeCompleteCount: data.totalGuideMeCompleteCount,
                                    dummyDonutData: data.dummyDonutData,
                                    dummyPieData: data.dummyPieData
                                }
                            })
                            props.noError(data.serviceAvailable)
                        }
                    }
                });
            }
          
        
    }

    const getGuideMeRadialChartData = useCallback(() => {
        let guideMeRadialChartOptions = [
            {
                key: "Progress",
                values: chartState.radialChartCount,
                color: ChartColors.orangesandybrown,
                gradientColor: ChartColors.lightseagreen,
            },
        ];
        return guideMeRadialChartOptions
    }, [chartState.radialChartCount])

    const getLineChart = useCallback((optn: string, api?: string) => {
        // let data_val:any = chartState;
        if (optn === "data") {
            return chartState.data;
        } else {
            return chartState.options;
        }
    },
        [chartState.data, chartState.options]
    );

    const getBarChart = useCallback((optn: string) => {
        if (optn === "data") {
            return chartState.data;
        } else {
            return chartState.options;
        }
    },
        [chartState.data, chartState.options]
    );

    const getPieChart = useCallback((type: string) => {
        if (type === 'donut') {

            return chartState.dummyDonutData;
        } else {
            return chartState.dummyPieData;

        }
    }, [chartState.dummyDonutData, chartState.dummyPieData]);

    const getItrClassName = (itrNumber: number) => {
        if (itrNumber === 0) {
            return props.width1Class;
        } else if (itrNumber === 1) {
            return props.width2Class;
        } else if (itrNumber === 2) {
            return props.width3Class;
        }

    }

    const getFilterValue = (option: string, data: any) => {
        switch (option) {
            case "applyEvent":
                setFilterValues((prevState: any) => {
                    return {
                        ...prevState,
                        eventSourceValue: data,
                    }
                })
                break;

            case "applyGuideStatus":
                setFilterValues((prevState: any) => {
                    return {
                        ...prevState,
                        guideStatusValue: data,
                    }
                })
                break;

            case "applySegmentFilter":
                setFilterValues((prevState: any) => {
                    return {
                        ...prevState,
                        segmentsGroup: {
                            segmentsFilter: data.segmentsFilter,
                            segmentTitle: data.segmentTitle
                        },
                    }
                })
                break;

            default:
                break;
        }

    }



    useEffect(() => {
        if(isInitialMount.current) {
            if(!props.data.tooltip_filter) {
                if ((filterValues.eventSourceValue !== "" || filterValues.guideStatusValue !== "")) {
                    switch (props.data.chartType) {
                        // Single Div
                        case 'WordCloud':
                            setChartState((prevState: any) => {
                                return {
                                    ...prevState,
                                    type: props.data.chartType,
                                    data_fetched: false,
                                    search_data_fetched: false
                                }
                            })
                            getWordCloudData(props.data.api);
                            getSearchStats(props.data.api);
                            break;
        
                        case 'LinePieChart':
                            setChartState((prevState: any) => {
                                return {
                                    ...prevState,
                                    type: props.data.chartType,
                                    data_fetched: false,
                                    lineChartMemoFetched: false,
                                    lineChartMemo: {},
                                }
                            })
                            getLineChart_data(props.data.api, props.data.sectionHeader, '', '', 1);
                            break;
        
                        case 'LineChart':
                            setChartState((prevState: any) => {
                                return {
                                    ...prevState,
                                    type: props.data.chartType,
                                    data_fetched: false,
                                    lineChartMemoFetched: false,
                                    lineChartMemo: {},
                                }
                            })
        
                            getLineChart_data(props.data.api, props.data.sectionHeader, props.data.tooltip_filter ? guideIds.list : '');
        
                            break;
        
                        case 'BarChart':
                            setChartState((prevState: any) => {
                                return {
                                    ...prevState,
                                    type: props.data.chartType,
                                    data_fetched: false,
                                }
                            })
                            getBarChart_data(props.data.api, props.data.sectionHeader);
                            break;
        
        
                        case 'LinePieDonutChart':
                            setChartState((prevState: any) => {
                                return {
                                    ...prevState,
                                    type: props.data.chartType,
                                    data_fetched: false,
                                    lineChartMemoFetched: false,
                                    lineChartMemo: {},
                                }
                            })
                            getLineChart_data(props.data.api, props.data.sectionHeader, '', '', 0);
                            break;
                    }
                } 
            } else {
                switch (props.data.chartType) {
                    case 'LineChart':
                        setChartState((prevState: any) => {
                            return {
                                ...prevState,
                                type: props.data.chartType,
                                data_fetched: false,
                                lineChartMemoFetched: false,
                                lineChartMemo: {},
                            }
                        })
                        getLineChart_data(props.data.api, props.data.sectionHeader, props.data.tooltip_filter ? guideIds.list : '');
                        break;
                }
            }
            isInitialMount.current = false;

            setTimeout(() => {
                isInitialMount.current = true;
            }, 1000);
        }
        
    }, [
        filterValues.eventSourceValue,
        filterValues.guideStatusValue,
        filterValues.segmentsGroup,
        fetched_details?.dates?.fdate,
        fetched_details?.dates?.tdate,
        fetched_details?.dates?.excludeFlag,
        fetched_details?.dates?.dateSelection,
        props.toolipFilter,
        guideIds.list 
    ]);

   

    const getGuideIds = (data: any) => {
        setGuideIds((prevState: any) => {
            return {
                ...prevState,
                list: data
            }
        })
        setChartState((prevState: any) => {
            return {
                ...prevState,
                type: props.data.chartType,
                data_fetched: false,
                lineChartMemoFetched: false,
                lineChartMemo: {},
            }
        })
        // getLineChart_data(props.data.api, props.data.sectionHeader, data);
    }

    const refreshSections = (chartType: any) => {
        setChartState((prevState: any) => {
            return {
                ...prevState,
                data_fetched: false
            }
        })

        switch (props.data.chartType) {
            // Single Div
            case 'WordCloud':
                setChartState((prevState: any) => {
                    return {
                        ...prevState,
                        type: props.data.chartType
                    }
                })
                getWordCloudData(props.data.api);
                getSearchStats(props.data.api);
                break;

            case 'LinePieChart':
                setChartState((prevState: any) => {
                    return {
                        ...prevState,
                        type: props.data.chartType,
                        lineChartMemoFetched: false,
                        lineChartMemo: {},
                    }
                })
                getLineChart_data(props.data.api, props.data.sectionHeader);
                break;

            case 'LineChart':
                setChartState((prevState: any) => {
                    return {
                        ...prevState,
                        type: props.data.chartType,
                        lineChartMemoFetched: false,
                        lineChartMemo: {},
                    }
                })
                getLineChart_data(props.data.api, props.data.sectionHeader, props.data.tooltip_filter ? guideIds.list : '');
                break;

            case 'BarChart':
                setChartState((prevState: any) => {
                    return {
                        ...prevState,
                        type: props.data.chartType
                    }
                })
                getBarChart_data(props.data.api, props.data.sectionHeader, '');
                break;


            case 'LinePieDonutChart':
                setChartState((prevState: any) => {
                    return {
                        ...prevState,
                        type: props.data.chartType,
                        lineChartMemoFetched: false,
                        lineChartMemo: {},
                    }
                })
                getLineChart_data(props.data.api, props.data.sectionHeader, '', '', +showMeTabKey.key);
                break;
        }
        // loadCharts();
    }

    const closeDeleteModal = () => {
        setDeleteModal((prevState: any) => {
            return {
                ...prevState,
                open: false
            }
        })
    }

    const openDeleteModal = () => {
        setDeleteModal((prevState: any) => {
            return {
                ...prevState,
                open: true,
                chartID: props.data.chartID
            }
        })
    }

    const deleteChart = () => {
        let params = {
            profileID: props.profileID,
            chartID: deleteModal.chartID
        };

        setChartState((prevState: any) => {
            return {
                ...prevState,
                data_fetched: false
            }
        })

        deleteCall(params, "CREATE_DASHBOARD", "").then((data: any) => {
            if (data.result === "success") {
                // getDashboard();
                setDeleteModal((prevState: any) => {
                    return {
                        ...prevState,
                        open: true,
                        chartID: props.data.chartID
                    }
                })

                toast.success(insightsLbls['successfullyDeleted'], {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                props.dispatchDelete();
                closeDeleteModal();

                for (let i = 0; i < data.data.data.payload.length; i++) {
                    if (data.data.data.payload[i].profileID === props.profileID) {
                        if (data.data.data.payload[i].profileDetails.length === 0) {
                            let queryParams = {
                                // app_code: "",
                            };
                            navigate({
                                pathname: `/dashboard/`,
                                search: qs.stringify(queryParams),
                            });

                            window.location.reload();
                        }
                    }
                }
            }
        });
    }

    const moveTo = (direction: string, index: number) => {
        props.dispatchDirection(direction, index)
    }

    const LineChartDashboard = useCallback((width: any) => {
        if (width) {
            setChartState((prevState: any) => {
                return {
                    ...prevState,
                    lineChartMemo: <Linechart
                        chartData={getLineChart("data", props.data.api)}
                        chartOptions={getLineChart("option", props.data.api)}
                        value_type={"count"}
                        height={activity_linechart_height}
                        margin={lineChartMargin}
                        yAxisLabel={insightsLbls['yAxisLabelCount']}
                        width={width}
                    ></Linechart>,
                    lineChartMemoFetched: true,
                };
            });

        }
        else {
            setChartState((prevState: any) => {
                return {
                    ...prevState,
                    lineChartMemo: <Linechart
                        chartData={getLineChart("data", props.data.api)}
                        chartOptions={getLineChart("option", props.data.api)}
                        value_type={"count"}
                        height={activity_linechart_height}
                        margin={lineChartMargin}
                        yAxisLabel={insightsLbls['yAxisLabelCount']}
                    ></Linechart>,
                    lineChartMemoFetched: true,
                };
            });
        }

        return (
            null
        );
    }, [getLineChart, lineChartMargin, activity_linechart_height]);


    const handleShowMeSwitch = (e: any) => {
        setShowMeTabKey((prevState: any) => {
            return {
                ...prevState,
                key: e
            };
        });

        setChartState((prevState: any) => {
            return {
                ...prevState,
                type: props.data.chartType,
                data_fetched: false,
                lineChartMemoFetched: false,
                lineChartMemo: {},
            }
        })
        getLineChart_data(props.data.api, props.data.sectionHeader, '', '', +e);
    };

    return (
        <>
            {props.data?.sectionHeader ?
                (
                    <SectionRefresh sectionTitle={props.data.sectionHeader} refreshClass={""}>
                        <i
                            className="fa fa-refresh pull-right pointer row"
                            aria-hidden="true" title={insightsLbls['refresh']}
                            onClick={() => refreshSections(props.data.chartType)}>
                        </i>

                        <i
                            className="fa fa-trash pull-right pointer marginTop-8 marginRight-5"
                            aria-hidden="true" title={insightsLbls['delete']}
                            onClick={() => openDeleteModal()}
                        >
                        </i>

                        {!props.lastElem && (<i
                            className="fa fa-arrow-down pull-right pointer marginTop-8 marginRight-5"
                            aria-hidden="true" title={insightsLbls['down']} onClick={() => moveTo('down', props.data.chartIndex)}
                        >
                        </i>
                        )}

                        {props.keyVal !== 0 &&
                            (<i
                                className="fa fa-arrow-up pull-right pointer marginTop-8 marginRight-5"
                                aria-hidden="true" title={insightsLbls['up']} onClick={() => moveTo('up', props.data.chartIndex)}
                            >
                            </i>
                            )
                        }

                    </SectionRefresh>
                ) : (null)}
            <CardContainer
                div1Classes={
                    "col-sm-12 col-lg-12 col-md-12 marginBottom-30 padding0 dashbordCharts"
                }
                div2Classes={
                    "card icon-text section-wrapper marginTop-0 chart-wrapper1 borderTop col-12 paddingLR-0"
                }
                //card-body(removed from div3Classes)
                div3Classes={"minHeight490 overflowY-hidden padding0"}
            >

                <React.Fragment>

                    <div className="filterContainer paddingLeft-15 paddingRight-15">
                        <div className="col-sm-4 col-md-4 col-lg-4">
                            <div className="appNameDiv marginLeft-10">
                                <p className="font600 font16 appName">{insightsLbls['application']}: {props.data.appTitle}</p>
                            </div>
                        </div>
                        <div className="col-sm-8 col-md-8 col-lg-8 displayFlex dashboardFilters-margin">
                            {/* <div className="spaceDiv marginLeft-10">
                        </div> */}


                            {!guideStatusNotApplicableList.includes(props.data.api[0]) && props.data.guide_filter && (<div className="floatRight guideStateFilter marginLeft-10">
                                {props.data.guideStatusFilter !== '' && (<StateFilter component={"customDashboard"} defaultValue={filterValues.guideStatusValue} guideStatusValue={getFilterValue} disabled={props.data.guideStatusFilter === ""}></StateFilter>)}
                            </div>)}

                            {props.data.event_filter && (<div className="floatRight eventSourceFilter marginLeft-10">
                                {props.data.eventSourceFilter !== '' && (<EventSource component={props.data.component === "tooltip" ? "customDashboardTooltips" : "customDashboard"} defaultValue={filterValues.eventSourceValue} eventSourceValue={getFilterValue} disabled={props.data.eventSourceFilter === ""}></EventSource>)}
                            </div>)}

                            {!segmentNotApplicableList.includes(props.data.api[0]) && props.data.segment_filter && (<div className="floatRight marginLeft-10">
                                {props.data.segmentsGroup !== '' && (<Segments component={"customDashboard"} defaultValue={props.data.segmentsGroup} segmentsFilterValue={getFilterValue}></Segments>)}
                            </div>)}

                            {props.data.tooltip_filter && (<div className="floatRight toolipFilter marginLeft-10">

                                {props.data.tooltipFilter

                                    ? (
                                        <TooltipModal
                                            appCode={props.data.appCode}
                                            dispatchGuidesList={getGuideIds}
                                            fromComponent='customDashboard'
                                            defaultSelected={props?.data?.tooltip_guideIds?.split('&guide_id=') ?? []}
                                            defaultValue={props?.data?.tooltipFilter}>
                                        </TooltipModal>
                                    )
                                    : (null)}
                            </div>)
                            }

                        </div>
                    </div>
                    <hr className="marginTop-0 marginBottom-0"></hr>
                    <div className="row marginLR-0">
                        {chartState.serviceAvailable ?
                            (
                                [...Array(props.sections)].map((e: any, itr: number) => (
                                    <React.Fragment key={itr}>

                                        <div
                                            className={getItrClassName(itr)}
                                            key={itr}>
                                            <div>
                                                <ContainerTooltip
                                                    div1Classes={"graph-title displayFlex width100 marginTop--30 "}
                                                    // div2Classes={""}
                                                    hTagClasses={"section-heading headingText alignCenter"}
                                                >
                                                    {
                                                        props.data.sectionTooltip !== undefined ?
                                                            (<SectionTooltip tooltipText={props.sections == 1 ? props.data.sectionTooltip : props.data.sectionTooltip[itr]} placement={chartState.type === "LinePieChart" && itr === 1 ? ("bottom") : (chartState.type === "LinePieDonutChart" && itr === 2 ? ("bottom") : ("right"))}></SectionTooltip>) : (null)
                                                    }
                                                </ContainerTooltip>
                                                <div className="col-sm-12 marginTop-50 overflowX chartdiv search_section row marginLR-0">
                                                    {chartState.data_fetched
                                                        ? (<>
                                                            {(() => {
                                                                switch (chartState.type) {
                                                                    // Single Div
                                                                    case 'WordCloud':
                                                                        return (
                                                                            (
                                                                                <>

                                                                                    <div className="col-sm-9 col-md-9 col-lg-9">
                                                                                        <div className="minHeight490">
                                                                                            {
                                                                                                chartState.word_data.length !== 0 ?
                                                                                                    (<WordCloud chartData={chartState.word_data}></WordCloud>)
                                                                                                    :
                                                                                                    (
                                                                                                        <div className="dataAvailable minHeight490">
                                                                                                            <div className="noDataText-font textCenter-absolute">
                                                                                                                {insightsLbls.noRecordFound}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-sm-3 col-md-3 col-lg-3">
                                                                                        <div className="alignCenter minHeight490 flexTiles">
                                                                                            {chartState.search_data.map((data: any, index: number) => {
                                                                                                return (
                                                                                                    <div className="" key={index}>
                                                                                                        <div className={"borderBottom_" + `${index}` + " card tiles-border tileBoxes"} >
                                                                                                            <div className="heigth100 width230" >
                                                                                                                <div className="stat-icon">
                                                                                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 search-tooltip">
                                                                                                                        <div className="searchTile-tooltip headingText displayBlock">

                                                                                                                            {
                                                                                                                                tooltip = (
                                                                                                                                    <Tooltip id="tooltip">
                                                                                                                                        <br></br>
                                                                                                                                        <div><span className="">{index == 0 ? insightsLbls.searchCount : (index == 1 ? insightsLbls.actionCount : "")}</span></div>
                                                                                                                                        <br></br>
                                                                                                                                    </Tooltip>
                                                                                                                                )
                                                                                                                            }

                                                                                                                            <OverlayTrigger placement={"bottom"} overlay={tooltip}>
                                                                                                                                <i className="fa fa-info-circle info-icon-color" style={{ marginLeft: '10px' }} aria-hidden="true" >
                                                                                                                                </i>
                                                                                                                            </OverlayTrigger>
                                                                                                                        </div>

                                                                                                                        <div className="">
                                                                                                                            <div className={"statColor_" + `${index}` + " stat-digit font28"}>{data.value}</div>
                                                                                                                            <div className="stat-digit">{data.key}</div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            })}
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        );

                                                                    case 'LinePieChart':
                                                                        return (
                                                                            <>
                                                                                {itr === 0
                                                                                    ? (chartState.lineChartMemoFetched ? (chartState.lineChartMemo) :
                                                                                        (<LineChartDashboard></LineChartDashboard>))
                                                                                    : (
                                                                                        <div className="padding0">
                                                                                            <div className=" alignCenter  row col-sm-12 col-md-12 col-lg-12 marginLR-0">
                                                                                                <RadialChart
                                                                                                    chartData={getGuideMeRadialChartData()}
                                                                                                    width={280}
                                                                                                    height={180}

                                                                                                    className={"margin-0-auto"}
                                                                                                ></RadialChart>

                                                                                                <div className="col-md-12 displayFlex alignCenter">
                                                                                                    <h5 className="font16 width100 textCenter">{insightsLbls['playedVsCompleted']}</h5>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="row marginLR-0 marginTop-30">
                                                                                                <div className="leftBox col-sm-6 padding0 minHeight130 overflowX chartdiv search_section section-wrapper-0 positionRelative">
                                                                                                    <div className="guide-count marginTop-10">
                                                                                                        <h3 className="count countHeight payCount-text">
                                                                                                            {chartState.totalGuideMeCount}
                                                                                                        </h3>
                                                                                                        <h5 className="countHeight font13">{insightsLbls['playedCount']}</h5>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="rightBox col-sm-6 padding0 minHeight130 overflowX chartdiv search_section section-wrapper-0 positionRelative borderBottomRight">
                                                                                                    <div className="guide-count marginTop-10">
                                                                                                        <h3 className="count countHeight completeCount-text">
                                                                                                            {chartState.totalGuideMeCompleteCount}
                                                                                                        </h3>
                                                                                                        <h5 className="countHeight font13">{insightsLbls['completedCount']}</h5>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </>
                                                                        );

                                                                    case 'LineChart':
                                                                        return (
                                                                            chartState.lineChartMemoFetched ? (chartState.lineChartMemo) :
                                                                                (<LineChartDashboard></LineChartDashboard>)
                                                                        );

                                                                    case 'BarChart':
                                                                        return (
                                                                            <Barchart
                                                                                chartData={getBarChart("data")}
                                                                                chartOptions={getBarChart("option")}
                                                                                value_type={"count"}
                                                                                height={activity_linechart_height}
                                                                                margin={lineChartMargin}>
                                                                            </Barchart>
                                                                        );

                                                                    case 'LinePieDonutChart':
                                                                        return (
                                                                            <>
                                                                                {itr === 0
                                                                                    && (
                                                                                        <div className="alignCenter justifyCenter minHeight400">
                                                                                            <Piechart
                                                                                                chartData={getPieChart('donut')}
                                                                                                valueFormat={'value'}
                                                                                                height={250}
                                                                                                width={250}
                                                                                                showLegends={true}
                                                                                                chartType={'donut'}
                                                                                                labelType={'percent'}
                                                                                                pieMargin={pie_margin}
                                                                                                readyData={true}>
                                                                                            </Piechart>
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                {itr === 1
                                                                                    && (
                                                                                        chartState.lineChartMemoFetched ? (chartState.lineChartMemo) :
                                                                                            (
                                                                                                <Tabs
                                                                                                    activeKey={showMeTabKey.key}
                                                                                                    onSelect={handleShowMeSwitch}
                                                                                                    id="showMe-tabs">
                                                                                                    <Tab eventKey={0} title={insightsLbls['slideshow']}>
                                                                                                        <div className="loaderHeight width100 displayFlex height100-percent top10 positionRelative minHeight350 minWidth500 overflowX-auto marginTop-30">
                                                                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                                                                <Linechart
                                                                                                                    chartData={getLineChart("data", props.data.api)}
                                                                                                                    chartOptions={getLineChart("option", props.data.api)}
                                                                                                                    value_type={"count"}
                                                                                                                    height={350}
                                                                                                                    margin={lineChartMargin}
                                                                                                                    yAxisLabel={insightsLbls['yAxisLabelCount']}
                                                                                                                    width={500}>
                                                                                                                </Linechart>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </Tab>
                                                                                                    <Tab eventKey={1} title={insightsLbls['video']}>
                                                                                                        <div className="loaderHeight width100 displayFlex height100-percent top10 positionRelative minHeight350 minWidth500 overflowX-auto marginTop-30">
                                                                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                                                                <Linechart
                                                                                                                    chartData={getLineChart("data", props.data.api)}
                                                                                                                    chartOptions={getLineChart("option", props.data.api)}
                                                                                                                    value_type={"count"}
                                                                                                                    height={350}
                                                                                                                    margin={lineChartMargin}
                                                                                                                    yAxisLabel={insightsLbls['yAxisLabelCount']}
                                                                                                                    width={500}>
                                                                                                                </Linechart>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </Tab>
                                                                                                    <Tab eventKey={2} title="GIF">
                                                                                                        <div className="loaderHeight width100 displayFlex height100-percent top10 positionRelative minHeight350 minWidth500 overflowX-auto marginTop-30">
                                                                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                                                                <Linechart
                                                                                                                    chartData={getLineChart("data", props.data.api)}
                                                                                                                    chartOptions={getLineChart("option", props.data.api)}
                                                                                                                    value_type={"count"}
                                                                                                                    height={350}
                                                                                                                    margin={lineChartMargin}
                                                                                                                    yAxisLabel={insightsLbls['yAxisLabelCount']}
                                                                                                                    width={500}>
                                                                                                                </Linechart>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </Tab>
                                                                                                </Tabs>
                                                                                            )
                                                                                    )
                                                                                }

                                                                                {itr === 2
                                                                                    && (
                                                                                        <div className="alignCenter justifyCenter minHeight400">
                                                                                            <Piechart
                                                                                                chartData={getPieChart('pie')}
                                                                                                valueFormat={'value'}
                                                                                                height={250}
                                                                                                width={250}
                                                                                                showLegends={true}
                                                                                                chartType={'pie'}
                                                                                                labelType={'percent'}
                                                                                                readyData={true}>
                                                                                            </Piechart>
                                                                                        </div>
                                                                                    )}
                                                                            </>
                                                                        );
                                                                }
                                                            })()}
                                                        </>)
                                                        : (
                                                            <div className="loaderHeight width100 displayFlex minHeight430 top-30 positionRelative">
                                                                <div className="displayFlex alignCenter margin-0-auto">
                                                                    <Loader></Loader>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ))
                            )
                            :
                            (
                                <div className="serviceAvailable minHeight450">
                                    <h5 className="errorCss card-heading font-weight-400 text-center">
                                        {insightsLbls.serviceNotAvailable}
                                    </h5>
                                </div>
                            )
                        }

                    </div>
                </React.Fragment>

            </CardContainer>


            <CommonModal
                modalState={deleteModal.open}
                dispatchModalState={closeDeleteModal}
                modalTitle={insightsLbls['deleteChart']}
                size={'md'}
                footerDisabled={false}
                exportModal={false}
                padding={true}
                modalDialogClass={''}
                dispatchModalOk={() => deleteChart()}
                dispatchModalCancel={closeDeleteModal}
                okBtn={insightsLbls['delete']}
                cancelBtn={insightsLbls['cancel']}
            >
                <div className="deleteChart">
                    {insightsLbls['deleteChartMsg']}
                </div>
            </CommonModal>
        </>
    )
}


export default withRouter(React.memo(Type1));
