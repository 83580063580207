import React, { useEffect, useState, useRef } from "react";
import { Dropdown } from "react-bootstrap";


import { useDispatch, useSelector } from "react-redux";
import { EventSourceState, LanguageState } from "../../store/Reducers";
import { setEventSource } from "../../store/Actions";


import "./EventSource.scss";
import { isConstructorDeclaration } from "typescript";
import { useLocation } from "react-router-dom";
import { useErrorBoundary } from 'react-error-boundary';



import { InsightLabels } from "labels";

interface CurrentState {
    eventSource: EventSourceState;
    languageData: LanguageState
}
interface CheckedInterFace {
    checked: any[],
    selectAllChecked: boolean,
    checkedTrue: any[],
    checkedFalse: any[]
}
const EventSource = (props: any) => {

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    const { showBoundary } = useErrorBoundary();
    const location = useLocation();
    const dispatch = useDispatch();
    const firstLoad = useRef(true);
    const secondLoad = useRef(true);
    let fetched_details = useSelector((state: CurrentState) => state);


    let eventSource: any[];
    if (props.component === "tooltips" || props.component === "customDashboardTooltips" || props.component === "goalsTooltips" || props.component === 'pushNotificationsBeacons') {
        eventSource = [
            { key: 'player', value: insightsLbls.webPlayer },
            { key: 'desktop_player', value: insightsLbls.desktopPlayer },
            { key: 'desktop_creator', value: insightsLbls.desktopCreator },
            { key: 'mobile_player', value: insightsLbls.mobilePlayer },
        ];
    }
    else {
        eventSource = [
            { key: 'player', value: insightsLbls.webPlayer },
            { key: 'creator', value: insightsLbls.webCreator },
            { key: 'desktop_player', value: insightsLbls.desktopPlayer },
            { key: 'desktop_creator', value: insightsLbls.desktopCreator },
            { key: 'mobile_creator', value: insightsLbls.mobileCreator },
            { key: 'mobile_player', value: insightsLbls.mobilePlayer },
        ];
    }


    const eventSourceLength = eventSource.length;
    let eventSourceValues: object = {
        eventSourceGuides: fetched_details.eventSource.eventSourceGuides,
        eventSourceGuideErrors: fetched_details.eventSource.eventSourceGuideErrors,
        eventSourceTutorialGuides: fetched_details?.eventSource?.eventSourceTutorialGuides,
        eventSourceTooltips: fetched_details.eventSource.eventSourceTooltips,
        eventSourceSearches: fetched_details.eventSource.eventSourceSearches,
        eventSourceUserEngagement: fetched_details.eventSource.eventSourceUserEngagement,
        eventSourceDashboard: fetched_details.eventSource.eventSourceDashboard,
        eventSourceGuideAutomation: fetched_details.eventSource.eventSourceGuideAutomation,
        eventSourceGoals: fetched_details.eventSource.eventSourceGoals,
        eventSourceExportEmailGuide: fetched_details.eventSource.eventSourceExportEmailGuide,
        eventSourceGoalsGuides: fetched_details.eventSource.eventSourceGoalsGuides,
        eventSourceGoalsTooltips: fetched_details.eventSource.eventSourceGoalsTooltips,
        eventSourcePushNotificationsBeacons: fetched_details.eventSource.eventSourcePushNotificationsBeacons,
        eventSourceTaskList: fetched_details.eventSource.eventSourceTaskList

        // eventSourceExportEmailTooltip: fetched_details.eventSource.eventSourceExportEmailTooltip,
        // eventSourceExportEmailSearches: fetched_details.eventSource.eventSourceExportEmailSearches,
    };

    const [checked, setChecked] = useState<CheckedInterFace>({
        checked: [],
        selectAllChecked: false,
        checkedTrue: [],
        checkedFalse: [],
    });

    const [eventSourceFilter, setEventSourceFilter] = useState({
        isOpen: false,
    });

    const [labelValue, setLabelValue] = useState({
        labelValue: "",
        selectedValue: ''

    });

    const toggleDropDown = (event: boolean) => {
        setEventSourceFilter((prevState: any) => {
            return {
                ...prevState,
                isOpen: event,
            };
        });
    };

    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            firstLoad.current = true
            getDefaultValue();
        }
    }, [fetched_details.languageData.languageData])

    const getDefaultValue = () => {
        try {
            const allSelectedGuides: string = "creator&event_source=player&event_source=desktop_creator&event_source=mobile_creator&event_source=mobile_player";
            const allSelectedTooltips: string = "player&event_source=desktop_creator&event_source=mobile_player";
            let defaultValue: any;
            if (props.component === "userEngagement") {
                defaultValue = fetched_details.eventSource.eventSourceUserEngagement;
            }
            else if (props.component === "guideErrors") {
                defaultValue = fetched_details?.eventSource?.eventSourceGuideErrors;
            }
            else if (props.component === "tutorialGuides") {
                defaultValue = fetched_details?.eventSource?.eventSourceTutorialGuides;
            }
            else if (props.component === "dashboard") {
                defaultValue = fetched_details.eventSource.eventSourceDashboard;
            }
            else if (props.component === "tooltips") {
                defaultValue = fetched_details.eventSource.eventSourceTooltips;
            }
            else if (props.component === "searches") {
                defaultValue = fetched_details.eventSource.eventSourceSearches;
            }
            else if (props.component === "guideAutomation") {
                defaultValue = fetched_details.eventSource.eventSourceGuideAutomation;
            }
            else if (props.component === "pushNotificationsBeacons") {
                defaultValue = fetched_details?.eventSource?.eventSourcePushNotificationsBeacons;
            }
            else if (props.component === "taskList") {
                defaultValue = fetched_details?.eventSource?.eventSourceTaskList;
            }
            else if (props.component === "goals") {
                defaultValue = firstLoad.current
                    && !(Array.isArray(props.defaultValue) && props.defaultValue.length === 0) ?
                    props.defaultValue :
                    fetched_details?.eventSource?.eventSourceGoals;
            }
            else if (props.component === "exportEmailGuide") {
                defaultValue = fetched_details.eventSource.eventSourceExportEmailGuide;
            }
            // else if (props.component === "exportEmailTooltip" ){
            //     defaultValue = fetched_details.eventSource.eventSourceExportEmailTooltip;
            // }
            // else if (props.component === "exportEmailSearches" ){
            //     defaultValue = fetched_details.eventSource.eventSourceExportEmailSearches;
            // }
            else if (props.component === "customDashboard" || props.component === "dashboardExportModal") {
                defaultValue = props.defaultValue;
            }
            else if (props.component === "customDashboardTooltips") {
                defaultValue = props.defaultValue;
            }
            else if (props.component === "goalsGuides") {
                defaultValue = secondLoad.current ? props.defaultValue : fetched_details.eventSource.eventSourceGoalsGuides;
            }
            else if (props.component === "goalsTooltips") {
                defaultValue = secondLoad.current ? props.defaultValue : fetched_details.eventSource.eventSourceGoalsTooltips;
            }
            else {
                defaultValue = fetched_details?.eventSource?.eventSourceGuides;
            }

            let urlData: String = '';
            const query = new URLSearchParams(location.search);

            query.forEach((v, k) => {
                if (k === 'event_source') {
                    if (urlData.length == 0) {
                        urlData = v;
                    } else {
                        urlData = urlData + "&event_source=" + v;
                    }
                }
            });


            if (urlData) {
                let eventFilterValues = urlData?.split('&event_source=');
                if (eventFilterValues) {
                    let cnt = eventFilterValues.length;
                    for (let itr0 = 0; itr0 < eventSource.length; itr0++) {
                        for (let itr1 = 0; itr1 < eventFilterValues.length; itr1++) {
                            if (eventFilterValues[itr1] === eventSource[itr0].key) {
                                cnt--;
                            }
                        }
                    }

                    if (cnt === 0) {
                        defaultValue = urlData;
                    } else {
                        defaultValue = 'player'
                    }
                } else {
                    if (props.component === "userEngagement") {
                        defaultValue = fetched_details.eventSource.eventSourceUserEngagement;
                    } else if (props.component === "tooltips") {
                        defaultValue = fetched_details.eventSource.eventSourceTooltips;
                    } else if (props.component === "searches") {
                        defaultValue = fetched_details.eventSource.eventSourceSearches;
                    } else if (props.component === "guideErrors") {
                        defaultValue = fetched_details.eventSource.eventSourceGuideErrors;
                    } else if (props.component === "guideAutomation") {
                        defaultValue = fetched_details.eventSource.eventSourceGuideAutomation;
                    } else if (props.component === "tutorialGuides") {
                        defaultValue = fetched_details?.eventSource?.eventSourceTutorialGuides; //check
                    } else if (props.component === "pushNotificationsBeacons") {
                        defaultValue = fetched_details?.eventSource?.eventSourcePushNotificationsBeacons; //check
                    } else if (props.component === "taskList" || props.component === "taskListDetail") {
                        defaultValue = fetched_details?.eventSource?.eventSourceTaskList; //check
                    } else {
                        defaultValue = fetched_details?.eventSource?.eventSourceGuides; //check
                    }
                }
            }

            let defaultValueArray = defaultValue?.split('&');
            let defaultValueArrayLength = defaultValueArray?.length;
            let checkedTrue: any[] = [];
            let checkedFalse: any[] = [];
            let newChecked: any[] = [];
            let finalChecked = eventSource;

            for (let i = 0; i < eventSourceLength; i++) {
                newChecked[i] = false;
                checkedTrue[i] = true;
                checkedFalse[i] = false;
                if (defaultValue === allSelectedGuides
                    || (props.component === "tooltips" && defaultValue === allSelectedTooltips)
                    || (props.component === "customDashboardTooltips" && defaultValue === allSelectedTooltips)
                    || (props.component === "goalsTooltips" && defaultValue === allSelectedTooltips)
                ) {
                    newChecked[i] = checkedTrue;
                    setChecked((prevState: any) => {
                        return {
                            ...prevState,
                            // newChecked: checkedTrue,
                            selectAllChecked: true,
                        };
                    });
                }
                else {
                    setChecked((prevState: any) => {
                        return {
                            ...prevState,
                            selectAllChecked: false,
                        };
                    });
                    for (let j = 0; j < defaultValueArrayLength; j++) {
                        if (defaultValueArray[j] === eventSource[i]?.key || defaultValueArray[j] === "event_source=" + eventSource[i]?.key) {
                            newChecked[i] = true;
                            break;
                        }
                    }
                }
            }

            setChecked((prevState: any) => {
                return {
                    ...prevState,
                    checked: newChecked,
                    checkedTrue: checkedTrue,
                    checkedFalse: checkedFalse,
                };
            });

            let j = 0
            for (let i = 0; i < newChecked.length; i++) {
                if (!newChecked[i]) {
                    finalChecked.splice(j, 1);
                }
                else {
                    j++;
                }
            }


            let paramsString = "";
            let labelString = "";
            if (finalChecked.length === eventSourceLength) {
                labelString += finalChecked.length
            }
            else if (finalChecked.length !== 0) {
                labelString += finalChecked.length;

            }
            for (let i = 0; i < finalChecked.length; i++) {
                if (i === 0) {
                    paramsString += finalChecked[i].key;
                }
                else if (i > 0) {
                    paramsString += "&event_source=" + finalChecked[i].key;
                }
            }

            if (firstLoad.current === true) {
                if (props.component === "userEngagement") {
                    eventSourceValues =
                    {
                        ...eventSourceValues,
                        eventSourceUserEngagement: paramsString
                    };
                }
                else if (props.component === "tutorialGuides") {
                    eventSourceValues =
                    {
                        ...eventSourceValues,
                        eventSourceTutorialGuides: paramsString
                    };
                }
                else if (props.component === "guideErrors") {
                    eventSourceValues =
                    {
                        ...eventSourceValues,
                        eventSourceGuideErrors: paramsString
                    };
                }
                else if (props.component === "pushNotificationsBeacons") {
                    eventSourceValues =
                    {
                        ...eventSourceValues,
                        eventSourcePushNotificationsBeacons: paramsString
                    };
                }
                else if (props.component === "dashboard") {
                    eventSourceValues =
                    {
                        ...eventSourceValues,
                        eventSourceDashboard: paramsString
                    };
                }
                // || props.match.path.includes("tooltips")
                else if (props.component === "tooltips") {
                    eventSourceValues =
                    {
                        ...eventSourceValues,
                        eventSourceTooltips: paramsString
                    };
                }
                else if (props.component === "searches") {
                    eventSourceValues =
                    {
                        ...eventSourceValues,
                        eventSourceSearches: paramsString
                    };
                } else if (props.component === "guideAutomation") {
                    eventSourceValues =
                    {
                        ...eventSourceValues,
                        eventSourceGuideAutomation: paramsString
                    };
                } else if (props.component === "goals") {
                    eventSourceValues =
                    {
                        ...eventSourceValues,
                        eventSourceGoals: paramsString,
                    };
                } else if (props.component === "exportEmailGuide") {
                    eventSourceValues =
                    {
                        ...eventSourceValues,
                        eventSourceExportEmailGuide: paramsString
                    };
                }
                else if (props.component === "goalsGuides") {
                    eventSourceValues =
                    {
                        ...eventSourceValues,
                        eventSourceGoalsGuides: paramsString
                    };
                } else if (props.component === "goalsTooltips") {
                    eventSourceValues =
                    {
                        ...eventSourceValues,
                        eventSourceGoalsTooltips: paramsString
                    };
                } else if (props.component === "customDashboard" || props.component === "dashboardExportModal") {
                    props.eventSourceValue("applyEvent", paramsString);
                } else if (props.component === "customDashboardTooltips") {
                    props.eventSourceValue("applyEvent", paramsString);
                } else if (props.component === "taskList") {
                    eventSourceValues =
                    {
                        ...eventSourceValues,
                        eventSourceTaskList: paramsString
                    };
                } else {
                    eventSourceValues =
                    {
                        ...eventSourceValues,
                        eventSourceGuides: paramsString
                    };
                }

                const value = paramsString.split('&');
                localStorage.setItem("eventSourceFilterState", JSON.stringify(eventSourceValues));
                dispatch(setEventSource(eventSourceValues));

                setLabelValue((prevState: any) => {
                    return {
                        ...prevState,
                        labelValue: labelString,
                        selectedValue: value[0]
                    };
                });
            }
            firstLoad.current = false;
            secondLoad.current = false;

        } catch (error) {
            showBoundary(error)
        }
    };

    const handleSelectAll = (e: any) => {
        try {
            setChecked((prevState: any) => {
                if (prevState.selectAllChecked === false) {
                    return {
                        ...prevState,
                        selectAllChecked: true,
                        checked: checked.checkedTrue
                    };
                }
                else {
                    return {
                        ...prevState,
                        selectAllChecked: false,
                        checked: checked.checkedFalse
                    };
                }
            });
        } catch (error) {
            showBoundary(error)
        }

    };

    const handleSelect = (e: any) => {
        try {
            selectChecked(e.target.value, e.target.checked)
        } catch (error) {
            showBoundary(error)
        }
    };


    const selectChecked = (index: number, option: boolean) => {
        try {
            setChecked((prevState: any) => {
                if (option === true) {
                    let newChecked = [...checked.checked];
                    newChecked[index] = option;
                    if (newChecked.every((value) => value === true)) {
                        return {
                            ...prevState,
                            checked: [...newChecked],
                            selectAllChecked: true,
                        };
                    } else
                        return {
                            ...prevState,
                            checked: [...newChecked],
                            selectAllChecked: false,
                        };
                }
                else if (option === false) {
                    let newChecked = [...checked.checked];
                    newChecked[index] = option;
                    return {
                        ...prevState,
                        checked: [...newChecked],
                        selectAllChecked: false,
                    };
                }
            });
        } catch (error) {
            showBoundary(error)
        }
    };


    const handleApply = () => {
        try {
            toggleDropDown(false);
            setEventSourceFilter((prevState: any) => {
                return {
                    ...prevState,
                    isOpen: false
                }
            })

            let newChecked = [...checked.checked];
            let finalChecked = eventSource;
            let j = 0
            for (let i = 0; i < newChecked.length; i++) {
                if (!newChecked[i]) {
                    finalChecked.splice(j, 1);
                }
                else {
                    j++;
                }
            }
            let paramsString = "";
            let labelString = "";
            if (finalChecked.length === eventSourceLength) {
                labelString += finalChecked.length
            } else if (finalChecked.length !== 0) {
                labelString += finalChecked.length;
            }

            for (let i = 0; i < finalChecked.length; i++) {
                if (i === 0) {
                    paramsString += finalChecked[i].key;
                }
                else if (i > 0) {
                    paramsString += "&event_source=" + finalChecked[i].key;
                }
            }

            setLabelValue((prevState: any) => {
                return {
                    ...prevState,
                    labelValue: labelString
                };
            });


            // localStorage.setItem('EVENT_SOURCE', paramsString);
            // || props.match.path.includes("user-engagement")
            if (props.component === "userEngagement") {
                eventSourceValues =
                {
                    ...eventSourceValues,
                    eventSourceUserEngagement: paramsString
                };
            }
            else if (props.component === "tutorialGuides") {
                eventSourceValues =
                {
                    ...eventSourceValues,
                    eventSourceTutorialGuides: paramsString
                };
            }
            else if (props.component === "guideErrors") {
                eventSourceValues =
                {
                    ...eventSourceValues,
                    eventSourceGuideErrors: paramsString
                };
            }
            else if (props.component === "pushNotificationsBeacons") {
                eventSourceValues =
                {
                    ...eventSourceValues,
                    eventSourcePushNotificationsBeacons: paramsString
                };
            }
            else if (props.component === "dashboard") {
                eventSourceValues =
                {
                    ...eventSourceValues,
                    eventSourceDashboard: paramsString
                };
            }
            // || props.match.path.includes("tooltips")
            else if (props.component === "tooltips") {
                eventSourceValues =
                {
                    ...eventSourceValues,
                    eventSourceTooltips: paramsString
                };
            }
            else if (props.component === "searches") {
                eventSourceValues =
                {
                    ...eventSourceValues,
                    eventSourceSearches: paramsString
                };
            }
            else if (props.component === "guideAutomation") {
                eventSourceValues =
                {
                    ...eventSourceValues,
                    eventSourceGuideAutomation: paramsString
                };
            }
            else if (props.component === "goals") {
                eventSourceValues =
                {
                    ...eventSourceValues,
                    eventSourceGoals: paramsString
                };
            }
            else if (props.component === "exportEmailGuide") {
                eventSourceValues =
                {
                    ...eventSourceValues,
                    eventSourceExportEmailGuide: paramsString
                };
            }
            else if (props.component === "goalsGuides") {
                eventSourceValues =
                {
                    ...eventSourceValues,
                    eventSourceGoalsGuides: paramsString
                };
            }
            else if (props.component === "goalsTooltips") {
                eventSourceValues =
                {
                    ...eventSourceValues,
                    eventSourceGoalsTooltips: paramsString
                };
            }
            else if (props.component === "customDashboard" || props.component === "dashboardExportModal") {
                props.eventSourceValue("applyEvent", paramsString);
            }
            else if (props.component === "customDashboardTooltips") {
                props.eventSourceValue("applyEvent", paramsString);
            } else if (props.component === "taskList") {
                eventSourceValues =
                {
                    ...eventSourceValues,
                    eventSourceTaskList: paramsString
                };
            }
            else {
                eventSourceValues =
                {
                    ...eventSourceValues,
                    eventSourceGuides: paramsString
                };
            }

            const value = paramsString.split('&');
            localStorage.setItem("eventSourceFilterState", JSON.stringify(eventSourceValues));
            dispatch(setEventSource(eventSourceValues));
            setLabelValue((prevState: any) => {
                return {
                    ...prevState,
                    selectedValue: value[0]
                };
            });
        } catch (error) {
            showBoundary(error)
        }
    };

    // `${props.component === 'customDashboard' || props.component === "customDashboardTooltips" ? "-dashboard" : ""}` +"-div " 

    return (
        <>
            {(props.component !== 'exportEmailGuide' && props.component !== 'goalsGuides') && (
                <p className={(props.component === "userEngagement" || props.component === "guideAutomation"  || props.component === "searches" || props.component === "pushNotificationsBeacons" || props.component === "taskList") ? "userEngement_eventSourceFilterTitle " : (props.component === "dashboardExportModal") ? "eventSourceModalFilterTitle" : "eventSourceFilterTitle"}>{insightsLbls.eventSourceFilterTitle}</p>
            )}
            <div className={props.component === 'customDashboard' || props.component === "customDashboardTooltips" ?
                "eventSource-dashboard-div "
                : props.component === "dashboardExportModal" ?
                    ("eventSource-div marginLeft-65 positionRelative")
                    : ("eventSource-div")}>

                <Dropdown id="dropdownMenuButton" show={eventSourceFilter.isOpen} onToggle={(event) => toggleDropDown(event)}>

                    <Dropdown.Toggle
                        className={
                            (
                                (props.component === "guideAnalytics" || props.component === "guideErrors" || props.component === "tutorialGuides" || props.component === "dashboard") ?

                                    ("source-but-event-source-guide buttonPositionEventSource")
                                    :
                                    (
                                        (props.component === "tooltips") ?
                                            ("source-but-event-source-tooltips buttonPositionEventSource")
                                            : (
                                                (props.component === "customDashboard" || props.component === "customDashboardTooltips")
                                                    ? ("source-but-event-source-dashboard")
                                                    : (props.component === "dashboardExportModal")
                                                        ? ("source-but-event-source-dashboard-export-modal")
                                                        : ("buttonPositionEventSource")
                                            )
                                    )

                            )
                            +
                            " btn btn-lg btn-outline-primar source-but-event-source positionAbsolute bootstrapDCWorkaround2"
                        }
                        >
                        
                        {props.compoment === 'exportEmailGuide'
                            ? (<span className="eventSource-count">{labelValue.labelValue}</span>)
                            : parseInt(labelValue.labelValue) - 1 > 0 && (<span className="eventSource-count">+{parseInt(labelValue.labelValue) - 1}</span>)
                        }


                        {props.compoment === 'exportEmailGuide'
                            ? (<span className="eventSource-label">{insightsLbls.eventSource}</span>)
                            : (<span className={parseInt(labelValue.labelValue) - 1 > 0 ? "eventSource-label" : "eventSource-label-leftPadding"}>{labelValue.selectedValue}</span>)}

                        <i aria-hidden="true" className={"switchApp-btn-icon " + (eventSourceFilter.isOpen ? "fa fa-chevron-up" : "fa fa-chevron-down")}></i>
                    </Dropdown.Toggle>

                    {/* <Dropdown.Toggle 
                    className={"btn btn-lg btn-outline-primar source-but-event-source positionAbsolute bootstrapDCWorkaround2 " +   
                                (
                                    (props.component === "guideAnalytics") ? 
                                        ("source-but-event-source-guide buttonPositionEventSource") 
                                        : 
                                        (
                                            (props.component === "tooltips") ? 
                                                ("source-but-event-source-tooltips buttonPositionEventSource") 
                                                : 
                                                (
                                                    (props.component === "customDashboard" || props.component === "customDashboardTooltips") ? 
                                                        ("source-but-event-source-dashboard") 
                                                        : ("buttonPositionEventSource")
                                                )
                                        )
                                )
                            }
                >
                    <i className="fa fa-sliders source-icon" aria-hidden="true"></i>
                    <span>{"Event Source " + labelValue.labelValue}</span>
                    <i aria-hidden="true" className={"switchApp-btn-icon " + (eventSourceFilter.isOpen ? "fa fa-chevron-up" : "fa fa-chevron-down")}></i>
                </Dropdown.Toggle> */}

                    <Dropdown.Menu className={(
                        (props.component === "guideAnalytics" || props.component === "tutorialGuides" || props.component === "dashboard" || props.component === "guideErrors") ?
                            ("source-menu-event-source-guide")
                            : (props.component === "customDashboard" || props.component === "customDashboardTooltips" ?
                                ("source-menu-event-source-dashboard")
                                : ((props.component === "tooltips") ?
                                    ("source-menu-event-source-tooltips")
                                    : (props.component === "dashboardExportModal" ?
                                        ("source-menu-event-source-dashboard-export-modal")
                                        : (null)
                                    )
                                )
                            )
                    ) + " dropdown-menu source-menu-event-source"} id="event_dropdown_menu">
                        <div
                            className="custom-control custom-checkbox mr-sm-2 displayFlex alignCenter marginLeft-20 marginTop-10 check-container
                        padding-chk-0 marginBottom-10">
                            <div className="custom-control custom-checkbox mr-sm-2 check-container padding-chk-0 pointer">
                                <label className="check-container displayFlex alignCenter exportCheck marginBottom-0 marginLeft--5 selectAll-div">
                                    <span className="marginLeft-10">{insightsLbls.selectAll}</span>
                                    <input type="checkbox" className="custom-control-input chk checkbox" id="customControlAutosizingEvntSrc"
                                        name="chk-box" value="all" onChange={handleSelectAll} checked={checked.selectAllChecked}></input>
                                    <span className="checkmark checkmarkBox-color displayInlineBlock top0"></span>
                                </label>
                            </div>
                        </div>
                        <hr className="marginBottom-0 marginTop-0"></hr>
                        <div className="eventSourceList">
                            {
                                eventSource.map((source: any, index: number) => {
                                    return (
                                        <div className="custom-control custom-checkbox mr-sm-2 displayFlex alignCenter  marginLeft-20 marginTop-10 marginBottom-10 check-container padding-chk-0">
                                            <div className="custom-control custom-checkbox mr-sm-2 check-container padding-chk-0 pointer">
                                                <label className="check-container displayFlex alignCenter exportCheck marginBottom-0 marginLeft--5">
                                                    <span className="marginLeft-10">{source.value}</span>
                                                    <input type="checkbox" className={"custom-control-input chk checkbox eventFiltermenu filter_" + index}
                                                        id="customControlAutosizing" name={source.key}
                                                        value={index} onChange={handleSelect} checked={checked.checked[index]}></input>
                                                    <span className="checkmark checkmarkBox-color displayInlineBlock top0"></span>
                                                </label>
                                            </div>
                                        </div>

                                    );

                                })
                            }
                        </div>
                        <hr className="marginBottom-0 marginTop-0"></hr>
                        {/* <div className="applyButton"> */}
                        {/* <button className="modal-save-btn events-apply-btn" onClick={handleApply} disabled={checked.checked.every((value) => value === false)}>Apply</button>
                    </div> */}
                        <div className="applyButton">
                            <button type="button" className="events-apply-btn modal-save-btn" onClick={handleApply} disabled={checked.checked.every((value) => value === false)}>{insightsLbls.apply}</button>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    )
}
export default React.memo(EventSource);