import { default as en_US } from 'utils/stringConstants/en_US';
import { default as fr_CA } from 'utils/stringConstants/fr_CA';
import { default as fr_FR } from 'utils/stringConstants/fr_FR';
import { default as ja_JP } from 'utils/stringConstants/ja_JP';
import { default as ko_KR } from 'utils/stringConstants/ko_KR';
import { default as pseudo } from 'build/insights_pseudo';

let insightLabels = en_US;
let selectedLang = localStorage.getItem('WEB_APP_LANGUAGE');


if (selectedLang === null || selectedLang === '' || selectedLang === undefined) {
    switch (process.env.REACT_APP_LANGUAGE) {
        case 'en_US':
            insightLabels = en_US;
            break;

        case 'fr_CA':
            insightLabels = fr_CA;
            break;

        case 'fr_FR':
            insightLabels = fr_FR;
            break;

        case 'ja_JP':
            insightLabels = ja_JP;
            break;

        case 'ko_KR':
            insightLabels = ko_KR;
            break;

        case 'pseudo':
            insightLabels = pseudo;
            break;

        default:
            insightLabels = en_US;
            break;
    }
} else {
    switch (selectedLang) {
        case 'en_US':
            insightLabels = en_US;
            break;

        case 'fr_CA':
            insightLabels = fr_CA;
            break;

        case 'fr_FR':
            insightLabels = fr_FR;
            break;

        case 'ja_JP':
            insightLabels = ja_JP;
            break;

        case 'ko_KR':
            insightLabels = ko_KR;
            break;

        case 'pseudo':
            insightLabels = pseudo;
            break;

        default:
            insightLabels = en_US;
            break;
    }
}

if (GmXt === undefined) var GmXt = {};

GmXt.engLbls = {};

GmXt.engLbls = {
    tutorialGuides: "Tutorial Guides",
    guideAutomation: "Guide Automation",
    cioDashboardTitle: "CIO Dashboard",
    guideSurvey: "Guide Survey",
    exitGuideSurvey: "Exit Guide Survey",
    guideStepSurvey: "Guide Step Survey",
    pushNotifications: "Push-Notifications",
    ductTape: "Duct Tape",
    docTitleGuides: "Guide Insights - Guides",
    docTitleTutorialGuides: "Guide Insights - Tutorial Guides",
    docTitleTooltips: "Guide Insights - Tooltips",
    docTitleGuideAutomation: "Automation Insights - Guide Automation",
    genericSurvey: "Generic Survey",
    guideWorkflow: " Guide Workflow",
    dynamicWorkflow: " Dynamic Workflow",
    workflows: "Workflows",
    guideInsights: "Guide Insights",
    appInsights: "App Insights",
    automationInsights: "Automation Insights",
    myGuideDashboard: "MyGuide Dashboard",
    tutorialGuide: "Tutorial Guide",
    manageGoals: "Manage Goal",
    manageSurvey: "Manage Survey",
    powerForm: "Power Form",
    digitalDuctTape: "Digital Duct Tape",
    guideMe: "GuideMe",
    showMe: "ShowMe",
    testMe: "TestMe",
    guide: "Guide",
    dashboard: "Dashboard",
    survey: "Survey",
    tooltip: "Tooltip",
    workflow: "Workflow",
    pages: "Pages",
    doItForMe: "DoItForMe",
    beacon: "Beacon",
    beacons: "Beacons",
    pushNotification: "Push-Notification",
    url: "URL",
    validation: "Validation",
    guideMeDashboard: "Guide Me",
    showMeDashboard: "Show Me",
    testMeDashboard: "Test Me",
    workFlow: "Workflow",
    guidance: "Guidance",
    tooltips: "tooltips",
    goal: "Goal",
    giphy: "GIF",
    downloadPptx: "PPT",
    pdf: "PDF",
    guidesTab: "Guides",
    page: "Page",
    creator: "Creator",
    superAdmin: "SuperAdmin",
    mgAdmin: "MG Admin",
    appAdmin: "App Admin",
    partnerAdmin: "Partner Admin",
    chatBot: "ChatBot",
    slideshow: "Slideshow",
    brandName: insightLabels.brandName,
    version: insightLabels.buildversion + " 2024.5.0",
    supportLinkText: insightLabels.supportLinkText,
    supportLink: insightLabels.mailto + ":support@csod.com",
    displaySupportLink: "support@csod.com",
    noReplyEmail: "reset-noreply@myguide.com",
    mailTo: "support@csod.com",
    jan: "Jan",
    feb: "Feb",
    mar: "Mar",
    apr: "Apr",
    may: "May",
    jun: "Jun",
    jul: "Jul",
    aug: "Aug",
    sep: "Sep",
    oct: "Oct",
    nov: "Nov",
    dec: "Dec",
    gif: "GIF",
    PNBandTooltips: "Push-notifications, Beacons & Tooltips",
    buttonText: "Login",
    environmentFilterTitle: 'Environment',
    segmentFilterTitle: 'Segment',
    feature: 'Feature',

    // not translated below all
    totalGuidesPlayCount: "The total number of guides played in GuideMe, DoItForMe and ShowMe mode.",
    role: "ROLE",
    performedSearches: "Performed Searches",
    searchesResultedIntoAction: "Searches Resulted In Action",
    exportStatus: "Export Status",
    myGuideGuidesPlayed: "MyGuide Guides Played",
    myGuideTutorialGuidesPlayed: "MyGuide Tutorial Guides Played",
    totalNumberOfTimesGuidePlayedInDoItForMe: "The total number of times guide played in DoItForMe mode.",
    creatorExtensionRequired: "For this feature to work, latest version of MyGuide Creator extension must be installed as extension in the browser",
    guidesInTooltipModeinTimeFrame: "Total no. of Tooltip shown within the given time frame.",
    guidesInPNModeinTimeFrame: "Total no. of guides played using Push-Notifications within the given time frame.",
    guidesInBeaconModeinTimeFrame: "Total no. of guides played using Beacons within the given time frame.",
    guidesInSurveyModeinTimeFrame: "Total no. of survey responses in given time frame.",
    pageVisits: "Page Visits",
    pageUsers: "Page Users",
    lineChartTooltipUserEngagementPage: "A line chart representing number of page visits and page users over time",
    numOfPageVisitsforUser: "Number of Page Visits for each User",
    selectDateRangeWithin180Days: "Select date range within 180 days!",
    ytd: 'YTD',
    userActivityGraph: "Line graph representing total user foot-fall across  selected set of application.",
    userActivityGraphDashboard: "Line graph representing total user foot-fall across  selected set of application.",
    totalUserCount: "Total User Count",
    ytdCalendarYear: " YTD (Calendar Year)",
};

GmXt.getPlayerLabels = function (lang) {
    var lab = insightLabels;
    if (lang) {
        var l = lang.substr(0, 2);
        if (GmXt.playerLbls[l]) {
            lab = GmXt.playerLbls[l];
        }
    }

    lab = GmXt.mergeLabels(lab, GmXt.engLbls);
    return lab;
};

GmXt.mergeLabels = function (labels, lbs) {
    for (var attr in lbs) {
        labels[attr] = lbs[attr];
    }
    return labels;
};

let insightLables = {};
GmXt.getAllLabels = function (lang) {
    GmXt.label = GmXt.getPlayerLabels(lang);
    const data = GmXt.mergeLabels(GmXt.engLbls, GmXt.getPlayerLabels(lang));
    return data;
};

insightLables = GmXt.getAllLabels();
export default insightLables;

let labelsData = new Promise(() => {
    return insightLables;
});


if (localStorage.getItem('IS_AUTHENTICATED') === 'true') {
    localStorage.removeItem('WEB_APP_LANGUAGE');
    // let urlData = process.env.REACT_APP_ANALYTICS_HOST + ApiRelativePaths.GET_LANGUAGE;
    // labelsData = axiosInstance.get(urlData)
    //     .then(res => {
    //         if (res.data.status === 'success') {
    //             switch (res.data.data['preferredLanguage']['UILanguagePreference']) {
    //                 case 'en_US':
    //                     insightLabels = en_US;
    //                     break;

    //                 case 'fr_CA':
    //                     insightLabels = fr_CA;
    //                     break;

    //                 case 'fr_FR':
    //                     insightLabels = fr_FR;
    //                     break;

    //                 case 'ja_JP':
    //                     insightLabels = ja_JP;
    //                     break;

    //                 case 'ko_KR':
    //                     insightLabels = ko_KR;
    //                     break;

    //                 case 'pseudo':
    //                     insightLabels = pseudo;
    //                     break;

    //                 default:
    //                     insightLabels = en_US;
    //                     break;
    //             }


    //         } else {
    //             insightLabels = en_US;
    //         }

    //         localStorage.setItem('WEB_APP_LANGUAGE',res.data.data['preferredLanguage']['UILanguagePreference']);
    //         return insightLabels;
    //     })
    //     .catch(err => {
    //         insightLabels = en_US;
    //         return insightLables;
    //     });
}


export const getSelectedLabels = labelsData.then(data => {
    const d = {
        ...GmXt.engLbls,
        ...data
    }
    return d
})




