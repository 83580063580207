// Modified by: Mrunal Mane
// On: 2024-05-10
// Reason: Removed language dependancy from useEffect initial load

import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import withRouter from "hoc/withRouter";

import { Tab, Tabs } from "react-bootstrap";

// Redux Imports
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { withReducer } from "store/withReducer";
import { AppsState, DatesState, DomainState, EventSourceState, StatusFilterState, EnvFilterState, CommonComponentState, LanguageState } from "store/Reducers";
import * as ActionTypes from "../../../store/Actions/ActionTypes";
import { GuideAnalyticsDetailState } from "store/Reducers/GuideInsights/guideAnalyticsDetail";
import { TutorialGuidesDetailState } from "store/Reducers/GuideInsights/tutorialGuidesDetail";
import tutorialGuideAnalyticsDetailReducer from "store/Reducers/GuideInsights/tutorialGuidesDetail";
import * as tutorialGuideAnalyticsDetailActions from "store/Actions/index";
import { getCallExportExcel, getCall } from "utils/ApiCallActions";


// import qs from 'querystring';
import { CommonUtils } from "utils/CommonUtils";
import { ChartColors, RetryApi } from "utils/GlobalConstants";
import qs from 'querystring';

// import NVD3Chart from "react-nvd3";


// SCSS Imports
import "./TutorialGuidesDetail.scss";


import SectionTooltip from "components/SectionTooltip/SectionTooltip";
import SectionRefresh from "components/Layouts/SectionRefresh";
import CardContainer from "components/Layouts/CardContainer";
import ContainerTooltip from "components/Layouts/ContainerTooltip";
import Loader from "components/Loader/Loader";
import SectionHeader from "components/Layouts/SectionHeader";
import Datepicker from "components/Calender/Datepicker";
import SectionButtons from "components/Layouts/SectionButtons";
import ExportToPdf from "components/ExportToPdf/ExportToPdf";
import CommonTable from "components/Table/CommonTable";
// import Piechart from "components/Chart/Piechart";

import Maintenance from "components/Maintenance/Maintenance";
import LineChart from "components/Chart/nvd3Charts/LineChart";
import EnvironmentFilter from "components/EnvironmentFilter/EnvironmentFilter";
import { useErrorBoundary } from 'react-error-boundary';


import SectionFilters from "components/Layouts/SectionFilters";
import { InsightLabels } from "labels";


interface CurrentState {
    domain: DomainState,
    tutorialGuideAnalyticsDetail: TutorialGuidesDetailState,
    eventSource: EventSourceState,
    envFilter: EnvFilterState,
    commonComponentData: CommonComponentState,
    languageData: LanguageState

}

const TutorialGuidesDetail: React.FC = (props: any) => {
    const { showBoundary } = useErrorBoundary();

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)

    

    const componentRef = useRef() as React.RefObject<HTMLDivElement>;

    const location = useLocation();
    const navigate = useNavigate();
    const { tutorial_id } = useParams();


    const dateZone: any = new Date().toString();
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];
    let urlData: any = {};
    const query = new URLSearchParams(location.search);
    query.forEach((v, k) => {
        urlData[k] = v;
    });

    const tutorialId = tutorial_id;

    let fetched_details = useSelector((state: CurrentState) => state);

    const dispatch = useDispatch();

    let lineChartMargin = {
        top: "50",
        right: "50",
        bottom: "50",
        left: "65",
    }
    let linechart_height = 400;
    let guide_linechart_height = 320;

    const TutorialGuidesMount = useRef(false);
    const TutorialGuidesTableMount = useRef(false);


    const [errorCount, setErrorCount] = useState({
        errorCount: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        showMaintenance_flag: false
    });

    const [loaderBtn, setLoaderBtn] = useState({
        loader0: false,
        loader1: false,
        disabled: true,
    });

    const [guideDetail, setGuideDetail] = useState({
        guideStatus: '',
        guideColor: '',
        guideStatus_flag: false,
        guideName: '',
        guideID: null,
    })

    const [guideAnalyticsDetailTutorialGuidesChart, setGuideAnalyticsDetailTutorialGuidesChart] = useState({
        loadTutorialGuidesChartData: [],
        loadTutorialGuidesChartOptions: [],
        guideAnalyticsDetailTutorialGuidesChartDataFetched: false,
        tutorialGuidesChart: {},
        tutorialGuidesChartFetched: false,
        errorMsg: ''
    });

    const [tutorialGuideDetailSummaryTable, setTutorialGuideDetailSummaryTable] = useState({
        tutorialGuideDetailSummaryTableDataFetched: false,
        searchQuery: '',
        sortKey: '',
        filterLabels: [],
        page: 1,
        showExport: true,
        defaultSort: {
            'sortColumn_index': 1,
            'sortValue': 'desc'
        },
        sortValue: 'desc',
        limit: 5,
        tablePagination: {
            offset: 0,
            data: [],
            perPage: 5,
            currentPage: 0
        },
        firstLoad: true,
        errorMsg: ''
    });

    const [tutorialTitle, setTutorialTitle] = useState('');


    // First Load @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        window.scrollTo(0, 0);
        if(fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }
     
    },[fetched_details.languageData.languageData])
    
    useEffect(() => {
        if (insightsLbls) {
            CommonUtils.LeftPanelHighlight(1, 2, 1, true, false);
            document.title = insightsLbls['docTitleTutorialGuideDetail'];
        }
    }, [insightsLbls])

    useEffect(() => {
        let queryParams: any = {
            app_id: fetched_details?.commonComponentData.appsData.appId,
            app_code: fetched_details?.commonComponentData.appsData.appCode,
            fdate: fetched_details?.commonComponentData?.datesData.fdate,
            tdate: fetched_details?.commonComponentData?.datesData.tdate,
        }

        let component_path: any[] = location.pathname.split('/');
        component_path = component_path.filter(function (el) {
            return el != '';
        });

        navigate({
            pathname: "/guide-insights/tutorial-guides-detail/" + component_path[component_path.length - 1] + '/',
            search: qs.stringify(queryParams),
        });



        if (fetched_details?.commonComponentData?.datesData?.source === 'component') {
            if (fetched_details?.commonComponentData?.datesData?.url?.includes('tutorial-guides-detail')) {
                getGuideStatus(tutorialId);
                getGuideAnalyticsDetailTutorialGuidesChartData();
                getTutorialGuideDetailTableData();
            }
        }

    }, [fetched_details?.commonComponentData.datesData]);



    // API Calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const getGuideStatus = (guide: any, apiErrorFlag?: string) => {
        try {
            let params = {
                "guide_id": guide,
                "app_id": fetched_details?.commonComponentData.appsData.appId
            };

            getCall(params, "GUIDE_STATUS", apiErrorFlag ?? '').then((data: any) => {
                if (data.result === "success") {
                    if (data.data.is_deleted) {
                        setGuideDetail((prevState: any) => {
                            return {
                                ...prevState,
                                // guideStatus: insightsLbls!== undefined && insightsLbls['deleted'],
                                guideColor: 'colorRed',
                                guideStatus_flag: true,
                            }
                        })
                    } else {
                        if (data.data.is_published) {
                            setGuideDetail((prevState: any) => {
                                return {
                                    ...prevState,
                                    // guideStatus: insightsLbls!== undefined && insightsLbls['published'],
                                    guideColor: 'colorGreen',
                                    guideStatus_flag: true,
                                }
                            })
                        } else {
                            setGuideDetail((prevState: any) => {
                                return {
                                    ...prevState,
                                    // guideStatus: insightsLbls!== undefined && insightsLbls['unpublished'],
                                    guideColor: 'colorGrey',
                                    guideStatus_flag: true,
                                }
                            })
                        }
                    }

                } else if (data.result === "retry") {
                    setTimeout(() => {
                        getGuideStatus(tutorialId, 'retry')
                    }, RetryApi.TIMEOUT)
                } else {
                    setGuideDetail((prevState: any) => {
                        return {
                            ...prevState,
                            // guideStatus: insightsLbls!== undefined && insightsLbls['unknown'],
                            guideColor: 'colorYellow',
                            guideStatus_flag: true,

                        }
                    })
                }
            });
        } catch (error) {
            showBoundary(error)
        }

    }
    useEffect(() => {
        try {
            if (insightsLbls !== undefined) {
                if (guideDetail.guideColor === 'colorRed') {
                    setGuideDetail((prevState: any) => {
                        return {
                            ...prevState,
                            guideStatus: insightsLbls !== undefined && insightsLbls['deleted'],
                        }
                    })
                } else if (guideDetail.guideColor === 'colorGreen') {
                    setGuideDetail((prevState: any) => {
                        return {
                            ...prevState,
                            guideStatus: insightsLbls !== undefined && insightsLbls['published'],
                        }
                    })
                } else if (guideDetail.guideColor === 'colorGrey') {
                    setGuideDetail((prevState: any) => {
                        return {
                            ...prevState,
                            guideStatus: insightsLbls !== undefined && insightsLbls['unpublished'],
                        }
                    })
                } else if (guideDetail.guideColor === 'colorYellow') {
                    setGuideDetail((prevState: any) => {
                        return {
                            ...prevState,
                            guideStatus: insightsLbls !== undefined && insightsLbls['unknown'],
                        }
                    })
                }
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [insightsLbls, guideDetail.guideColor])

    const getGuideAnalyticsDetailTutorialGuidesChartData = (apiErrorFlag?: string) => {
        const params = {
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            "time_zone": timeZone,
            'tutorial_id': tutorialId,
            "event_source": fetched_details?.eventSource.eventSourceTutorialGuides,
            'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData.datesData.realTimeCheck,
            'env_code': fetched_details?.envFilter.envCode ?? '',
            'app_id': fetched_details?.commonComponentData.appsData.appId
        };

        tutorialGuideAnalyticsDetailActions.getTutorialGuideAnalyticsDetailData(
            params,
            "TUTORIAL_GUIDES_DETAILS_CHART",
            ActionTypes.SET_GUIDE_ANALYTICS_DETAILS_TUTORIAL_GUIDES_CHART_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => dispatch(res));
    };


    const getTutorialGuideDetailTableData = (apiErrorFlag?: string) => {
        const params = {
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            "time_zone": timeZone,
            'tutorial_id': tutorialId,
            "event_source": fetched_details?.eventSource.eventSourceTutorialGuides,
            'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData.datesData.realTimeCheck,
            "query": '',
            "app_id": fetched_details?.commonComponentData.appsData.appId,
            "sort_by": tutorialGuideDetailSummaryTable.sortKey,
            "order": tutorialGuideDetailSummaryTable.sortValue,
            "page_index": tutorialGuideDetailSummaryTable.page,
            "page_size": tutorialGuideDetailSummaryTable.limit,
            "limit": tutorialGuideDetailSummaryTable.limit,
            'env_code': fetched_details?.envFilter.envCode ?? ''
        };

        tutorialGuideAnalyticsDetailActions.getTutorialGuideAnalyticsDetailData(
            params,
            "TUTORIAL_GUIDES_DETAILS_SUMMARY_TABLE",
            ActionTypes.SET_GUIDE_TUTORIAL_SUMMARY_TABLE_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => dispatch(res));
    };


    const exportTutorialGuidestableExcel = (data: any) => {
        let params = {
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            "time_zone": timeZone,
            'tutorial_id': tutorialId,
            "event_source": fetched_details?.eventSource.eventSourceTutorialGuides,
            'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData.datesData.realTimeCheck,
            "query": '',
            "app_id": fetched_details?.commonComponentData.appsData.appId,
            "sort_by": tutorialGuideDetailSummaryTable.sortKey,
            "order": tutorialGuideDetailSummaryTable.sortValue,
            "page_index": tutorialGuideDetailSummaryTable.page,
            "page_size": tutorialGuideDetailSummaryTable.limit,
            "limit": tutorialGuideDetailSummaryTable.limit,
            'env_code': fetched_details?.envFilter.envCode ?? ''
        };
        getCallExportExcel(params, 'TUTORIAL_GUIDES_DETAILS_SUMMARY_TABLE', data);
    }

    //Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const refreshSections = (optn: number) => {
        try {
            enableExportPdf(optn, false);
            if (optn === 0) {
                setGuideAnalyticsDetailTutorialGuidesChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsDetailTutorialGuidesChartDataFetched: false,
                        tutorialGuidesChartFetched: false,
                        tutorialGuidesChart: {}
                    };
                });
                getGuideAnalyticsDetailTutorialGuidesChartData();
            }
            else if (optn === 1) {
                setTutorialGuideDetailSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        tutorialGuideDetailSummaryTableDataFetched: false,
                        searchQuery: '',
                        sortKey: '',
                        filterLabels: [],
                        page: 1,
                        showExport: true,
                        defaultSort: {
                            'sortColumn_index': 1,
                            'sortValue': 'desc'
                        },
                        sortValue: 'desc',
                        limit: 5,
                        tablePagination: {
                            offset: 0,
                            data: [],
                            perPage: 5,
                            currentPage: 0
                        },
                        firstLoad: true
                    };
                });
            }
        } catch (error) {
            showBoundary(error)
        }

    };

    const apiCatchError = (index: number, option: number) => {
        try {
            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    errorCount: errorCount.errorCount.map((item: any, i: any) =>
                        i === index ? option : item
                    ),
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    };

    const enableExportPdf = (loaderNumber: number, val: boolean) => {
        try {
            setLoaderBtn((prevState: any) => {
                return {
                    ...prevState,
                    ['loader' + loaderNumber]: val
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const handletutorialGuideTableEvents = useCallback((option: string, data: any) => {
        try {
            switch (option) {
                case "sortColumn":
                    setTutorialGuideDetailSummaryTable((prevState) => {
                        return {
                            ...prevState,
                            sortKey: data.sortKey,
                            sortValue: data.sortValue,
                            page: 1,
                            defaultSort: {
                                sortColumn_index: data.index,
                                sortValue: data.sortValue,
                            },
                            firstLoad: false
                        }
                    });
                    break;

                case "changePage":
                    setTutorialGuideDetailSummaryTable((prevState) => {
                        return {
                            ...prevState,
                            page: data + 1,
                            firstLoad: false
                        }
                    });
                    break;

                case "searchTxt":
                    setTutorialGuideDetailSummaryTable((prevState) => {
                        return {
                            ...prevState,
                            page: 1,
                            searchQuery: data,
                            firstLoad: false
                        }
                    });
                    break;

                case "changeEntries":
                    setTutorialGuideDetailSummaryTable((prevState) => {
                        return {
                            ...prevState,
                            page: 1,
                            limit: data,
                            tablePagination: {                   // object that we want to update
                                ...prevState.tablePagination,    // keep all other key-value pairs
                                perPage: data       // update the value of specific key
                            },
                            firstLoad: false
                        }
                    });
                    break;

                case "exportExcel":
                    exportTutorialGuidestableExcel(data)
                    break;
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [tutorialGuideDetailSummaryTable]);

    // UseEffects @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

    // Tutorial Guides Chart
    useEffect(() => {
        try {
            if (
                fetched_details?.tutorialGuideAnalyticsDetail?.guideAnalyticsDetailTutorialGuidesChartData?.data !== undefined &&
                fetched_details?.tutorialGuideAnalyticsDetail?.guideAnalyticsDetailTutorialGuidesChartData?.result === "success"
            ) {
                TutorialGuidesMount.current = true;
                let tutorialGuidesChartData: any = [];

                tutorialGuidesChartData = fetched_details.tutorialGuideAnalyticsDetail.guideAnalyticsDetailTutorialGuidesChartData.data;

                setGuideAnalyticsDetailTutorialGuidesChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadTutorialGuidesChartData: [...tutorialGuidesChartData],
                        guideAnalyticsDetailTutorialGuidesChartDataFetched: true,
                        errorMsg: ''
                    };
                });
                apiCatchError(0, 0);
                enableExportPdf(0, true)

            } else if (fetched_details.tutorialGuideAnalyticsDetail.guideAnalyticsDetailTutorialGuidesChartData.result === "retry") {
                getGuideAnalyticsDetailTutorialGuidesChartData('retry')
            } else if (fetched_details.tutorialGuideAnalyticsDetail.guideAnalyticsDetailTutorialGuidesChartData.result === "error") {
                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;
                if (fetched_details.tutorialGuideAnalyticsDetail.guideAnalyticsDetailTutorialGuidesChartData.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2
                }
                setGuideAnalyticsDetailTutorialGuidesChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsDetailTutorialGuidesChartDataFetched: true,
                        errorMsg: errorMsg
                    };
                });
                apiCatchError(0, errorFlag);
                enableExportPdf(0, false)
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [fetched_details?.tutorialGuideAnalyticsDetail?.guideAnalyticsDetailTutorialGuidesChartData]);

    useEffect(() => {
        try {
            if (
                fetched_details?.tutorialGuideAnalyticsDetail?.guideAnalyticsDetailTutorialGuidesTableData?.data !== undefined &&
                fetched_details?.tutorialGuideAnalyticsDetail?.guideAnalyticsDetailTutorialGuidesTableData?.result === "success"
            ) {
                TutorialGuidesTableMount.current = true;
                setTutorialGuideDetailSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        tutorialGuideDetailSummaryTableDataFetched: true,
                        errorMsg: ''
                    };
                });

                setTutorialTitle(fetched_details?.tutorialGuideAnalyticsDetail?.guideAnalyticsDetailTutorialGuidesTableData?.data?.tutorialTitle);

                apiCatchError(1, 0);
                enableExportPdf(1, true);

            } else if (fetched_details?.tutorialGuideAnalyticsDetail?.guideAnalyticsDetailTutorialGuidesTableData?.result === "retry") {
                getTutorialGuideDetailTableData('retry')
            } else if (fetched_details.tutorialGuideAnalyticsDetail?.guideAnalyticsDetailTutorialGuidesTableData?.result === "error") {
                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;
                if (fetched_details.tutorialGuideAnalyticsDetail.guideAnalyticsDetailTutorialGuidesChartData.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }
                setTutorialGuideDetailSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        tutorialGuideDetailSummaryTableDataFetched: true,
                        errorMsg: errorMsg
                    };
                });
                apiCatchError(1, errorFlag);
                enableExportPdf(1, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details?.tutorialGuideAnalyticsDetail?.guideAnalyticsDetailTutorialGuidesTableData]);

    // Call summary table api on table related actions changes
    useEffect(() => {
        try {
            if (fetched_details?.tutorialGuideAnalyticsDetail?.guideAnalyticsDetailTutorialGuidesTableData?.data) {
                enableExportPdf(1, false);
                getTutorialGuideDetailTableData();
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [
        tutorialGuideDetailSummaryTable.sortKey,
        tutorialGuideDetailSummaryTable.sortValue,
        tutorialGuideDetailSummaryTable.defaultSort,
        tutorialGuideDetailSummaryTable.page,
        tutorialGuideDetailSummaryTable.searchQuery,
        tutorialGuideDetailSummaryTable.limit,
    ]);

    useEffect(() => {
        try {
            if (loaderBtn.loader0 && loaderBtn.loader1) {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: false,
                    };
                });
            }
            else {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: true,
                    };
                });
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [loaderBtn.loader0, loaderBtn.loader1])


    useEffect(() => {
        try {
            const result = errorCount.errorCount.every((val) => val === 1);

            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    showMaintenance_flag: result
                }
            })
        } catch (error) {
            showBoundary(error)
        }


    }, [errorCount.errorCount])
    // Chart Renders and Callbacks @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

    const LineChartTutorialGuides = useCallback(() => {
        try {
            setGuideAnalyticsDetailTutorialGuidesChart((prevState: any) => {
                return {
                    ...prevState,
                    tutorialGuidesChart: <LineChart
                        chartEntities={['tutorial_play', 'tutorial_unique_play', 'tutorial_complete']}
                        optionEntities={
                            [
                                {
                                    key: insightsLbls['totalPlayed'],
                                    color: ChartColors.orangesandybrown, area: true
                                },
                                {
                                    key: insightsLbls['uniquePlayed'],
                                    color: ChartColors.darkturquoise, area: true
                                },
                                {
                                    key: insightsLbls['completed'],
                                    color: ChartColors.lightseagreen, area: true
                                }
                            ]}
                        chartData={fetched_details?.tutorialGuideAnalyticsDetail?.guideAnalyticsDetailTutorialGuidesChartData?.data}
                        value_type={"count"}
                        height={linechart_height}
                        margin={lineChartMargin}
                        yAxisLabel={insightsLbls['yAxisLabelCount']}
                        refId="tutorialGuideAnalyticsDetail_chart"
                    // parseIntFlag={true}
                    >
                    </LineChart>,
                    tutorialGuidesChartFetched: true,
                };
            });
            return (
                null
            );
        } catch (error) {
            showBoundary(error)
        }

    }, [lineChartMargin, linechart_height]);

    const navigateToMainPage = () => {
        let queryParams = {
            app_id: fetched_details?.commonComponentData.appsData.appId,
            app_code: fetched_details?.commonComponentData.appsData.appCode,
            fdate: fetched_details?.commonComponentData?.datesData.fdate,
            tdate: fetched_details?.commonComponentData?.datesData.tdate,
            event_source: fetched_details?.commonComponentData?.eventSource.eventSourceTutorialGuides,
            state: fetched_details?.commonComponentData?.stateFilterData.stateFilter,
            segment: fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter,
            env: fetched_details?.commonComponentData?.envFilter?.envCode,
        }
        navigate({
            pathname: `/guide-insights/tutorial-guides/`,
            search: qs.stringify(queryParams)
        })
    }



    return (
        <section className="demo guideAnalyticsDetail width100" ref={componentRef}>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <SectionHeader>
                        <li>
                            <span className="cursorPointer" onClick={() => navigateToMainPage()}>{insightsLbls['tutorialGuide']}</span>
                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                        </li>
                        <li className="active">{insightsLbls['tutorialGuideDetail']}</li>
                    </SectionHeader>
                </div>

                <SectionFilters>
                    <EnvironmentFilter component={"guideAnalyticsDetail"} disabled={true} />
                    <Datepicker source={'component'} />
                </SectionFilters>
            </div>

            <SectionButtons sectionTitle={tutorialTitle} svgImage={"guides"} className={'marginTop-50'}>
                <ExportToPdf
                    componentRef={componentRef}
                    source={"Guide Analytics Detail"}
                    widthDividedBy={12}
                    disabled={loaderBtn.disabled}
                ></ExportToPdf>
            </SectionButtons>

            <div className="displayInlineFlex">
                {guideDetail.guideStatus_flag
                    ? (<div className="">
                        <span className={`${guideDetail.guideColor}` + " guideStatus"}>
                            {guideDetail.guideStatus}
                        </span>
                    </div>)
                    : (<div className="displayFlex alignCenter margin-0-auto">
                        <Loader width={50} height={'auto'} margin={'none'}></Loader>
                    </div>)
                }
            </div>
            <div id="guideAnalyticsDetailSection">

                {process.env.REACT_APP_WESTPAC === 'false' &&
                    (
                        <>
                            {
                                <SectionRefresh sectionTitle={insightsLbls.tutorialGuideDetail} refreshClass={"marginTop-30"}>
                                    <i className="fa fa-refresh pull-right pointer row displayFlex" aria-hidden="true" title={insightsLbls['refresh']} onClick={() => refreshSections(0)}></i>
                                </SectionRefresh>
                            }
                            {/* <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant"> */}
                            <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                                div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                                div3Classes={"col-sm-12 minHeight490 height100-percent padding15"}>
                                <ContainerTooltip div1Classes={""} div3Classes={""} hTagClasses={"section-heading headingText"}>
                                    <SectionTooltip tooltipText={
                                        {
                                            "subTitle": insightsLbls.activity,
                                            "tooltipText": insightsLbls.guidesPlayedViaDoItForMe,
                                            "childern": [
                                                {
                                                    "key": insightsLbls.totalPlayed + ":",
                                                    "value": insightsLbls.totalNumberOfGuidesPlayed
                                                },
                                                {
                                                    "key": insightsLbls.uniquePlayed + ":",
                                                    "value": insightsLbls.totalNumberOfUniqueGuidesPlayed
                                                },
                                                {
                                                    "key": insightsLbls.completed + ":",
                                                    "value": insightsLbls.totalNumberOfCompleteGuidesPlayed
                                                }
                                            ]
                                        }
                                    } placement="right"></SectionTooltip>
                                </ContainerTooltip>
                                <div className="col-sm-12 paddingTop-20 overflowX chartdiv search_section">
                                    {guideAnalyticsDetailTutorialGuidesChart.guideAnalyticsDetailTutorialGuidesChartDataFetched ? (
                                        errorCount.errorCount[0] === 0 ? (
                                            guideAnalyticsDetailTutorialGuidesChart.loadTutorialGuidesChartData.length === 0 ? (
                                                <div className="noDataText-font textCenter-absolute">
                                                    {insightsLbls.noRecordFound}
                                                </div>
                                            ) : (
                                                guideAnalyticsDetailTutorialGuidesChart.tutorialGuidesChartFetched ?
                                                    <>{guideAnalyticsDetailTutorialGuidesChart.tutorialGuidesChart}</> :
                                                    (<LineChartTutorialGuides></LineChartTutorialGuides>)
                                            )
                                        ) : (
                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                {guideAnalyticsDetailTutorialGuidesChart.errorMsg}
                                            </h5>
                                        )
                                    ) : (
                                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                            <div className="displayFlex alignCenter margin-0-auto">
                                                <Loader></Loader>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </CardContainer>

                            {
                                <SectionRefresh sectionTitle={insightsLbls.tutorialGuideDetailSummary} refreshClass={"marginTop-30"}>
                                    <i className="fa fa-refresh pull-right pointer row displayFlex" aria-hidden="true" title={insightsLbls['refresh']} onClick={() => refreshSections(1)}></i>
                                </SectionRefresh>
                            }

                            <CardContainer
                                div1Classes={"col-sm-12 col-lg-12 col-md-12  padding0 "}
                                div2Classes={"card icon-text section-wrapper marginTop-0 chart-wrapper1 borderTop col-12 paddingLR-0"}
                                div3Classes={"card-body minHeight490  overflowY-hidden"}>
                                <div className="col-sm-12  overflowX chartdiv search_section">
                                    <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                        <SectionTooltip tooltipText={
                                            {
                                                "subTitle": insightsLbls.detailedSummary,
                                                "tooltipText": insightsLbls.tableOfGuidePlayActivities,
                                                "childern": [
                                                    {
                                                        "key": insightsLbls.stepTitle + ":",
                                                        "value": insightsLbls.titleOfStep
                                                    },
                                                    {
                                                        "key": insightsLbls.stepPlayedCount + ":",
                                                        "value": insightsLbls.numberOfTimesStepPlayed
                                                    },
                                                    {
                                                        "key": insightsLbls.humanInterventionCount + ":",
                                                        "value": insightsLbls.humanInterventionCountTimes
                                                    },
                                                    {
                                                        "key": insightsLbls.skippedStepCount + ":",
                                                        "value": insightsLbls.skippedStepCountTimes
                                                    },
                                                    {
                                                        "key": insightsLbls.failedOnStep + ":",
                                                        "value": insightsLbls.failedOnStepTimes
                                                    }
                                                ]
                                            }
                                        } placement="right"></SectionTooltip>
                                    </ContainerTooltip>
                                    <div className="col-sm-12 paddingTop-20 overflowX chartdiv search_section">
                                        {
                                            tutorialGuideDetailSummaryTable.tutorialGuideDetailSummaryTableDataFetched ? (
                                                errorCount.errorCount[1] === 0 ? (
                                                    <div className="commonSummaryTable">
                                                        <CommonTable tableId="tutorialGuideDetailTable"
                                                            data={fetched_details.tutorialGuideAnalyticsDetail.guideAnalyticsDetailTutorialGuidesTableData.data}
                                                            currentPage={tutorialGuideDetailSummaryTable.page}
                                                            barColors=""
                                                            linkPresent={false}
                                                            showExport={true}
                                                            showSearch={false}
                                                            showEntries={true}
                                                            defaultSort={tutorialGuideDetailSummaryTable.defaultSort}
                                                            tablePagination={tutorialGuideDetailSummaryTable.tablePagination}
                                                            tableEvent={handletutorialGuideTableEvents}
                                                            firstTimeloadFlag={fetched_details.tutorialGuideAnalyticsDetail.guideAnalyticsDetailTutorialGuidesTableData.firstTimeload} //check
                                                            modalTable={false}
                                                        />
                                                    </div>
                                                )
                                                    : errorCount.errorCount[1] === 1 ? (
                                                        <h5 className="errorCss card-heading font-weight-400 text-center">
                                                            {tutorialGuideDetailSummaryTable.errorMsg}
                                                        </h5>
                                                    )
                                                        : null)
                                                : (
                                                    <div className="loaderHeight width100 displayFlex minHeight430 top-30 positionRelative">
                                                        <div className="displayFlex alignCenter margin-0-auto">
                                                            <Loader></Loader>
                                                        </div>
                                                    </div>
                                                )
                                        }
                                    </div>
                                </div>
                            </CardContainer>
                        </>
                    )
                }

            </div>
            <div className="overlayCursor" id="overlayCursor"></div>
            {errorCount.showMaintenance_flag && (
                <Maintenance></Maintenance>
            )}
        </section>
    )
}

export default withRouter(withReducer("tutorialGuideAnalyticsDetail", tutorialGuideAnalyticsDetailReducer)(TutorialGuidesDetail));
